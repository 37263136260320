import { useContext } from 'react';
import { AddTerminalContext } from '../../pages/Terminals/AddTerminal';
import SubmitLoader from '../SubmitLoader/SubmitLoader';
import './select.scss';

const SubTotal = () => {
  const {
    state: { step, buttonValue, loading },
    selectedPOS,
    onChangeStep,
    makePayment,
    total,
    wallet,
  } = useContext(AddTerminalContext);
  return (
    <div className="address font-default mt-20">
      <div className="d-flex justify-content-between">
        <h4 className="fs-18 text-grey fw-700">Caution Fee</h4>
        <h4 className="fs-16 text-orange fw-700">
          ₦ {(!isNaN(total) && total.toLocaleString()) || 0}
        </h4>
      </div>
      <div className="text-center mt-20">
        {step === 'select' && (
          <button
            disabled={selectedPOS?.length < 1}
            className="orange-button extra-padding"
            onClick={() => onChangeStep('merchantForm', 'Continue')}
          >
            {buttonValue}
          </button>
        )}
        {step === 'merchantForm' && (
          <button
            className="orange-button extra-padding"
            onClick={() => onChangeStep('pay', 'Pay now')}
          >
            {loading ? <SubmitLoader /> : buttonValue}
          </button>
        )}
        {step === 'pay' && (
          <button
            className="orange-button extra-padding"
            onClick={makePayment}
            // disabled={step === "pay" && total > wallet?.activeBalance}
          >
            {loading ? <SubmitLoader /> : buttonValue}
          </button>
        )}
      </div>
    </div>
  );
};

export default SubTotal;
