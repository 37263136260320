import { useState } from 'react';
import { isEmpty } from 'lodash';

const useUser = (refresh = false) => {
  let userDt = {};
  try {
    const { user, token, posUserProfile } = JSON.parse(
      localStorage.getItem('userDetails')
    );
    userDt = { user, token, posUserProfile };
  } catch {
    userDt = {};
  }
  const [user, setUser] = useState(userDt?.user);
  const [token, setToken] = useState(userDt?.token);
  const [posUserProfile, setposUserProfile] = useState(userDt?.posUserProfile);

  const getUser = async (userData, tokenData) => {
    if (!isEmpty(tokenData)) {
      setUser(userData);
      setToken(tokenData);
      setposUserProfile(posUserProfile);
    } else {
      if (refresh) {
        window.location.reload();
      }
    }
  };

  /*   const setUserToLocal = (userData) => {
    const dt = JSON.stringify(userData);
    localStorage.setItem("userDetails", dt);
  }; */

  return {
    user,
    posUserProfile,
    token,
    getUser,
  };
};
export default useUser;
