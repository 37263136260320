import React, { useState } from 'react';
// @mui
import { Box, DialogActions, Stack, TextField } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LoadingButton } from '@mui/lab';
import CurrencyFormat from 'react-currency-format';
import PropTypes from 'prop-types';
import SettingsMenus from '../../../components/settingsMenus';
import Loader from '../../../../../components/Loader';
import Button from '@material-ui/core/Button';

export const errorCircle = (
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="50" fill="#FDEDEA" />
    <path
      d="M63.0423 40.4852L60.5161 37.959L50.5007 47.9744L40.4852 37.959L37.959 40.4852L47.9744 50.5007L37.959 60.5161L40.4852 63.0423L50.5007 53.0269L60.5161 63.0423L63.0423 60.5161L53.0269 50.5007L63.0423 40.4852Z"
      fill="#FF5349"
    />
  </svg>
);

ErrorTemp.propTypes = {
  close: PropTypes.func,
  loading: PropTypes.bool,
  message: PropTypes.string,
  circle: PropTypes.bool,
  titleTop: PropTypes.string,
  titleDown: PropTypes.string,
  retryTxt: PropTypes.string,
  retryCB: PropTypes.func,
};

export default function ErrorTemp({
  close,
  loading,
  message,
  retryTxt,
  retryCB,
  titleTop,
  titleDown,
  okText,
}) {
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Stack spacing={3}>
          {titleTop && (
            <Box alignSelf="center" textAlign="center" mt={5} mb={7}>
              <Box className="heading">{titleTop}</Box>
            </Box>
          )}
          <Box alignSelf="center" textAlign="center" mt={15}>
            <h1>{errorCircle}</h1>
            <Box mb={7} mt={5}>
              <Box className="heading">{titleDown}</Box>
              <p className="mt-3 sub-heading">{message}</p>
            </Box>
          </Box>

          <div className="d-flex justify-content-between">
            <DialogActions className="justify-content-center">
              <div className="justify-center d-flex">
                <button className="grey-button text-orange btn" onClick={close}>
                  {okText ? okText : 'Ok'}
                </button>
              </div>
            </DialogActions>
            <DialogActions className="justify-content-center">
              <div className="justify-center d-flex">
                <button className="orange-button" onClick={retryCB}>
                  {retryTxt || 'Retry'}
                </button>
              </div>
            </DialogActions>
          </div>
        </Stack>
      )}
    </div>
  );
}
