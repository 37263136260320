import React from 'react';
import PropTypes from 'prop-types';
import { Route, Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element: Component, render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!localStorage.getItem('token')) {
          if (
            new URLSearchParams(props.location.search).get('from') === 'shared'
          ) {
            window.location.href = `/login?from=shared&id=${props.match.params.id}`;
          }
          localStorage.setItem('state-url', location.pathname);
          return (
            <Navigate
              to={{
                pathname: '/login',

                state: { from: props.location },
              }}
            />
          );
        }
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: null,
  render: null,
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  render: PropTypes.shape(),
  location: PropTypes.shape(),
};

export default ProtectedRoute;
