export const baseUrlList = {
  auth: `${process.env.REACT_APP_POS_BASE_URL}/auth-service/api/v1`,
  agent: `${process.env.REACT_APP_POS_BASE_URL}/agency-service/api/v1/agent`,
  agent2fa: `${process.env.REACT_APP_BASE_URL}/agency-service/api/v1`,
  terminal: `${process.env.REACT_APP_POS_BASE_URL}/terminal-service/api/v1`,
  transactions: `${process.env.REACT_APP_BASE_URL}/transaction-service/api/v1`,
  merchant: `${process.env.REACT_APP_BASE_URL}/merchant-service/api/v1`,
  audit: `${process.env.REACT_APP_BASE_URL}/auditlog-service/api/v1`,
  dispute: `${process.env.REACT_APP_POS_BASE_URL}/wayapos-settlement-service`,
  settlement: `${process.env.REACT_APP_POS_BASE_URL}/wayapos-settlement-service/api/v1`,
  withdrawal: `${process.env.REACT_APP_BASE_URL}/temporal-service/api/v1`,
  cardService: `${process.env.REACT_APP_BASE_URL}/card-service`,
  contact: `${process.env.REACT_APP_BASE_URL}/contact-service`,
  bills: `${process.env.REACT_APP_BASE_URL}/billspayment-service`,
  notifications: `${process.env.REACT_APP_BASE_URL}/notification-service`,
  kyc: `${process.env.REACT_APP_BASE_URL}/pos-kyc-service/kyc`,
  kycUpload: `${process.env.REACT_APP_WAYABANK_BASE_URL}/kyc-service/wayapos`,
  bankReferal: `${process.env.REACT_APP_BASE_URL}/transaction-service/api/v1/transactions`,
  role: `${process.env.REACT_APP_BASE_URL}/wayapos-role-service/api/v1`,
  commission: `${process.env.REACT_APP_BASE_URL}/middleware-service/api/v1`,
  referral: `${process.env.REACT_APP_BASE_URL}/referral-service/api/v1`,
  bvn: `${process.env.REACT_APP_WAYABANK_BASE_URL}/bvn-service`,
  settings: `${process.env.REACT_APP_BASE_URL}/settings-service/api/v1`,
  middleware: `${process.env.REACT_APP_BASE_URL}/middleware-service`,
  file: `${process.env.REACT_APP_BASE_URL}/file-resource-service`,
  nips: `${process.env.REACT_APP_WAYABANK_BASE_URL}/nip-bvn-service`,
  wayaKyc: `${process.env.REACT_APP_BASE_URL}/kyc-servicev2`,
  wayaAuth: `${process.env.REACT_APP_WAYABANK_BASE_URL}/auth-service/api/v1`,


};
