import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Modal, ModalHeader } from 'reactstrap';
import Loader from '../../Loader';
import { ProfileContext } from '../../../../store/context/ProfileContext';
import avatar2 from '../../../../assets/image/avatar2.svg';
import { getFriends } from '../../../pages/Wayagram/container';
import Button from '../../Button';

const Invite = (props) => {
  const {
    showModal,
    hideModal,
    btnLoading,
    handleInvite,
    selected,
    setSelected,
    btnText,
    title,
  } = props;
  const navigate = useNavigate();

  const {
    profile: { userId },
  } = useContext(ProfileContext);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllFriends = async () => {
    setLoading(true);
    const friends = await getFriends(userId);
    setMembers(friends?.friends);
    setLoading(false);
  };

  useEffect(() => {
    getAllFriends();
    return () => {
      setMembers([]);
    };
  }, []);

  return (
    <Modal
      isOpen={showModal}
      centered
      toggle={() => hideModal(false)}
      size="md"
    >
      <ModalHeader>{title || 'Invite Friends'}</ModalHeader>
      {loading ? (
        <div className="mx-auto my-lg-5">
          <Loader />
        </div>
      ) : (
        <div className="request-cont">
          {members?.length ? (
            members.map((member) => (
              <div className="request" key={member?.id}>
                <div
                  className="req-img"
                  onClick={() => {
                    navigate(`/wayagram-profile/${member?.id}`);
                  }}
                >
                  <img src={member?.avatar || avatar2} alt="" />
                </div>
                <div className="req-det">
                  <div
                    className="det-det"
                    onClick={() => {
                      navigate(`/wayagram-profile/${member?.id}`);
                    }}
                  >
                    <div>{member?.displayName}</div>
                    <div>{`@${member?.username}`}</div>
                  </div>

                  <div
                    className="det-button"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <Button
                      type="button"
                      onClick={() => {
                        setSelected(member?.id);
                        if (btnText === 'Message') {
                          handleInvite(member);
                        } else {
                          handleInvite(member?.id);
                        }
                      }}
                      loading={btnLoading && member?.id === selected}
                      content={btnText || 'Invite'}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="wl-100 my-4 text-center">No Friends</div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default Invite;
