import { useEffect, useRef, useState } from 'react';

export const CustomIcon = ({ name, IconComp, ...rest }) => {
  const ImportedIconRef = useRef();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        // Changing this line works fine to me
        ImportedIconRef.current = IconComp;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }
  return null;
};
