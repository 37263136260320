import React, { useState } from 'react';
import swal from 'sweetalert';
import { NotificationManager } from 'react-notifications';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router';
import { TopWrapper } from './style';
import bannerImage from '../../../assets/image/banner.jpg';
import avatarImg from '../../../assets/image/avatar3.png';
import Button from '../Button';
import WayagramProfile from '../Modals/Profile/WayagramProfile';
import Container from '../Container';
import truncateWithEllipses from '../../../utils/truncate';
import { handleFollow, handleUnFollow } from '../../pages/Wayagram/container';

export default function TopSection(props) {
  const [showProfile, setShowProfile] = useState(false);
  const {
    loggedInUser,
    profileData,
    connectionStatus,
    setTrigger,
    btnLoading,
    setBtnLoading,
  } = props;

  const loggedInUserId = loggedInUser ? loggedInUser?.id : '';
  const userId = profileData?.id;
  /* eslint-disable one-var */
  let followersCount,
    followingCount,
    postCount,
    displayName,
    username,
    avatar,
    coverImage,
    profileId;
  if (loggedInUserId !== userId) {
    followersCount = profileData?.followers;
    followingCount = profileData?.followings;
    postCount = profileData?.posts;
    displayName = profileData?.displayName;
    username = profileData?.username;
    avatar = profileData?.avatar;
    coverImage = profileData?.coverImage;
    profileId = profileData?.UserId;
  } else {
    followersCount = loggedInUser?.followers;
    followingCount = loggedInUser?.followings;
    postCount = loggedInUser?.posts;
    displayName = loggedInUser.displayName;
    username = loggedInUser?.username;
    avatar = loggedInUser?.avatar;
    coverImage = loggedInUser?.coverImage;
    profileId = loggedInUser?.UserId;
  }
  const navigate = useNavigate();
  const handleFollowUser = async () => {
    setBtnLoading(true);
    if (connectionStatus.following) {
      const res = await handleUnFollow(loggedInUserId, username);
      if (res.status) {
        setTrigger(true);
        NotificationManager.success(res.message, 'success!', 3000);
      } else {
        swal('Oops!', res.message, 'error');
      }
    }
    if (!connectionStatus.following) {
      const res = await handleFollow(loggedInUserId, username);
      if (res.status) {
        setTrigger(true);
        NotificationManager.success(res.message, 'success!', 3000);
      } else {
        swal('Oops!', res.message, 'error');
      }
    }
  };

  return (
    <TopWrapper>
      <div
        className="banner-div"
        style={{
          backgroundImage: `url(${coverImage || bannerImage})`,
          backgroundSize: 'cover',
          height: '140px',
          backgroundPosition: 'center',
        }}
      >
        {/* <img src={coverImage || bannerImage} alt="banner" /> */}
      </div>
      <div className="avatar-section d-flex mb-4">
        {userId ? (
          <img
            src={avatar || avatarImg}
            alt="avatar"
            style={{ borderRadius: '50%' }}
          />
        ) : (
          <Skeleton circle width={65} height={65} />
        )}
        {userId ? (
          <div className="ml-auto">
            {loggedInUserId === userId ? (
              <Button
                className="btn btn-primary"
                content="Edit Profile"
                onClick={() => setShowProfile(true)}
              />
            ) : connectionStatus ? (
              <Button
                className="btn btn-primary"
                content={connectionStatus.following ? 'Following' : 'Follow'}
                onClick={handleFollowUser}
                loading={btnLoading}
              />
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div
        className="info-section pe-3 ps-3"
        // style={loggedInUserId !== userId ? { marginTop: '100px' } : {}}
      >
        <p className="mb-0 pb-0">
          {displayName ? `${displayName}` : <Skeleton width={55} height={20} />}
        </p>
        <p className="text-muted">
          {username ? `@${username}` : <Skeleton width={30} height={20} />}
        </p>

        <p className="bio">
          {profileData?.bio ? truncateWithEllipses(profileData?.bio, 140) : ''}
        </p>
        <div className="d-flex count">
          <Container
            className="me-3"
            onClick={() => {
              navigate(`/following/${profileId}?active=followers`);
            }}
          >
            <span className="text-normal me-1">{followersCount}</span>
            <span className="s-text">followers</span>
          </Container>
          <Container
            className="me-3"
            onClick={() => {
              navigate(`/following/${profileId}?active=following`);
            }}
          >
            <span className="text-normal me-1">{followingCount}</span>
            <span className="s-text">following</span>
          </Container>
          <div className="">
            <span className="text-normal me-1">{postCount}</span>
            <span className="s-text">post</span>
          </div>
        </div>
      </div>

      {showProfile ? (
        <WayagramProfile showModal={showProfile} hideModal={setShowProfile} />
      ) : (
        ''
      )}
    </TopWrapper>
  );
}

// TopSection.defaultProps = {
//   profileData: {},
//   loggedInUser: {},
// };

// TopSection.propTypes = {
//   profileData: PropTypes.shape.isRequired,
//   loggedInUser: PropTypes.shape.isRequired,
// };
