import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { DashboardContext } from '../../pages/Dashboard/Dashboard';
import CountCards from './CountCards';
import './dashboard.scss';

const TransactionCount = () => {
  const {
    state: { failed, refunded, pending, abandoned, successful },
  } = useContext(DashboardContext);

  return (
    <div className="transaction-count">
      <Row>
        <Col xs={6} lg={4} xl={3} className="mt-2 mt-xl-0">
          <CountCards
            countTitle="Successful Transactions count"
            countValue={successful || 0}
          />
        </Col>
        <Col xs={6} lg={4} xl={3} className="mt-2 mt-xl-0">
          <CountCards
            countTitle="Pending Transactions Count: "
            countValue={pending || 0}
          />
        </Col>
        <Col xs={6} lg={4} xl={2} className="mt-2 mt-xl-0">
          <CountCards
            countTitle="Abandoned Transactions count"
            countValue={abandoned || 0}
          />
        </Col>
        <Col xs={6} lg={4} xl={2} className="mt-2 mt-xl-0">
          <CountCards
            countTitle="Failed Transactions Count"
            countValue={failed || 0}
          />
        </Col>
        <Col xs={6} lg={4} xl={2} className="mt-2 mt-xl-0">
          <CountCards
            countTitle="Refunded Transactions Count"
            countValue={refunded || 0}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransactionCount;
