import React, { useState, useEffect, useContext } from 'react';
import { Pagination } from '@mui/material';
import Table from '../../../shared/Table/DisputeTable';
import Layout from '../../../shared/Layout';
import DisputeWrapper from './style';
// import search from '../../../../assets/search.svg';
// import Delete from '../../../../assets/image/delete.svg';
// import log from '../../../../assets/log.svg';
import './index.css';
import {
  customBaseUrl,
  httpGet,
  // httpPostFormData,
} from '../../../../action/http';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { ProfileContext } from '../../../../store/context/ProfileContext';
// import { dateFormater } from '../../../../utils/formatDate';
import Stats from './stats';
import moment from 'moment';
import WithdrawCommission from './withdraw';
import { TablePagination } from '@material-ui/core';

const Index = (props) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  const [ticketId, setTicketId] = useState('');
  const [status, setStatus] = useState('');
  const [filteredObj, setFilteredObj] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(0);
  const [allDis, setAllDis] = useState([]);
  const [rej, setRej] = useState(0);
  const [resolve, setRes] = useState(0);
  const [rev, setRev] = useState(0);
  const [meta, setMeta] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const { userDetails } = useContext(ProfileContext);

  const getCommission = async () => {
    showLoader();
    const pages = page > 0 ? page - 1 : page;
    const res = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/fetchReferralCustomers?pageNo=${
        pages + 1
      }&pageSize=${rowsPerPage}`
    );
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
    if (res?.status) {
      console.log('req', res?.data?.content);
      setData(res?.data?.content);
      setMeta(res?.data?.totalElements);
    }
    hideLoader();
    console.log('dataDis', data);
  };
  const getStat = async () => {
    const allDisp = await httpGet(
      `${customBaseUrl.referalUrl}/api/v1/fetchReferralTransactionCommission/stats`
    );
    /*  const rejected = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-rejected/${userDetails.userId}`
    );
    const resolved = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-resolved/${userDetails.userId}`
    );
    const review = await httpGet(
      `${customBaseUrl.wayapayDispute}/total-under_review/${userDetails.userId}`
    );  */
    setAllDis(allDisp?.data);
    setRej(0);
    setRes(0);
    setRev(0);
    // const url = `https://services.staging.wayabank.ng/complaint-service/disputes`;
    // const res = await httpGet(url);
  };

  const handleFilter = (obj) => {
    setFilteredObj(obj);
  };
  const handleChange = (event, value) => {
    setPage(value);
    // eslint-disable-next-line
  };

  // // console.log('xxx>>>', data);
  // const createDisputeType = async () => {
  //   // const TypeData = {
  //   //   disputeTypeDescription: 'string',
  //   //   disputeTypeName: 'string',
  //   //   userId: 0,
  //   // };
  //   const fmd = new FormData();
  //   fmd.append('disputeTypeName', 'Socials');
  //   fmd.append('disputeTypeDescription', 'nothing yet');
  //   fmd.append('userId', 0);

  //   showLoader();
  //   const res = await httpPostFormData(
  //     '/api/v1/dispute/addDisputeType',
  //     fmd,
  //     customBaseUrl.disputeUrl
  //   );
  //   console.log('createDisputeType', res);
  //   if (res.status) {
  //     setData(res?.data?.content);
  //   }
  //   hideLoader();
  // };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    if (filteredObj) {
      console.log('filteredobj', filteredObj);
      if (
        (filteredObj.type && filteredObj.type !== '') ||
        (filteredObj.ticketId && filteredObj.ticketId !== '') ||
        (filteredObj.status && filteredObj.status !== '')
      ) {
        const d = data.filter(
          (x) =>
            x.category === filteredObj.type ||
            x.trackingNumber === filteredObj.ticketId ||
            x.status === filteredObj.status
        );
        setFiltered(d);
        console.log('filter2', filtered);
      } else {
        setFiltered(data);
        console.log('filter2', filtered);
      }
    } else {
      setFiltered(data);
      console.log('filter3', filtered);
    }
  }, [data, filteredObj]);

  console.log(setData);
  useEffect(() => {
    getStat();
    getCommission();
    //  setFiltered(data);
  }, [userDetails.userId, page]);

  return (
    <Layout route={props} currentPage="commission">
      <DisputeWrapper>
        <Stats
          data={data}
          header={[
            { name: 'USER NAME', key: 'name' },
            { name: 'USER EMAIL', key: 'email' },
            { name: 'USER PHONE', key: 'phoneNumber' },
            { name: 'SIGN UP DATE', key: 'createdAt' },
            { name: 'BUSINESS TYPE', key: 'businessType' },
            { name: 'ORGANIZATION NAME', key: 'organizationName' },
          ]}
          exportData={
            searchText
              ? data?.filter(
                  (e) =>
                    e?.email
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase()) ||
                    e?.phoneNumber
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase())
                )
              : data
          }
          allDis={allDis?.totalReferred ?? 0}
          res={allDis?.totalRevenueGenerated ?? 0}
          rev={allDis?.totalFail ?? 0}
          rej={allDis?.totalSuccess ?? 0}
          tranCount={(allDis?.countFail ?? 0) + (allDis?.countSuccess ?? 0)}
          type={type}
          setType={setType}
          ticketId={ticketId}
          setTicketId={setTicketId}
          status={status}
          setStatus={setStatus}
          handleFilter={handleFilter}
          setSearchText={setSearchText}
          setShowWithdraw={setShowWithdraw}
        />
        <Table
          columns={[
            // { title: 'TRACKING NUMBER', field: 'trackingNumber' },
            // { title: 'DATE', field: 'transactionDate' },
            // { title: 'STATUS', field: 'status' },
            // { title: 'ACTION', field: 'action' },
            { title: 'USER NAME', field: 'name' },
            { title: 'USER EMAIL', field: 'email' },
            // { title: 'CATEGORY', field: 'category' },
            { title: 'USER PHONE', field: 'phoneNumber' },
            {
              title: 'SIGN UP DATE',
              field: 'createdAt',
              render: (row) => moment(row?.createdAt).format('DD/MM/YYYY'),
            },
            {
              title: 'BUSINESS TYPE',
              field: 'businessType',
              render: (row) => row?.businessType ?? 'N/A',
            },
            {
              title: 'ORGANIZATION NAME',
              field: 'organizationName',
              render: (row) => row?.organizationName ?? 'N/A',
            },
          ]}
          data={
            searchText
              ? data?.filter(
                  (e) =>
                    e?.email
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase()) ||
                    e?.phoneNumber
                      ?.toLowerCase()
                      ?.includes(searchText?.toLowerCase())
                )
              : data
          }
        />
        <Pagination
          count={Math.ceil(meta / rowsPerPage)}
          page={page}
          onChange={handleChange}
        />
      </DisputeWrapper>
      {showWithdraw ? (
        <WithdrawCommission
          showModal={showWithdraw}
          hideModal={setShowWithdraw}
        />
      ) : null}
    </Layout>
  );
};

export default Index;
