import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import swal from 'sweetalert';
import Report from '../../../shared/Modals/ReportAndDispute';
import Layout from '../../../shared/Layout';
import truncate from '../../../../utils/truncate';
import { customBaseUrl, httpDelete, httpGet } from '../../../../action/http';
import '../index.scss';
import './style.scss';
import { MomentWrapper } from '../style';
import GramCard from '../../../shared/Cards/Gram';
import {
  getPageById,
  getPagePosts,
  getPageFollowers,
  inviteToAPage,
} from '../container';
import RightSidebar from '../RightSidebar';
import CustomDropdown from '../../../shared/Dropdown';
import Loader from '../../../shared/Loader';
// import { ProfileContext } from '../../../../store/context/ProfileContext';
import cakeImage from '../../../../assets/image/cake.png';
import search from '../../../../assets/image/search.svg';
import imgPlaceholder from '../../../../assets/image/imgPlaceholder.png';
import ConfirmModal from '../../../shared/Modals/Confirm/confirmationModal';
import { hideLoader, showLoader } from '../../../../utils/loader';
import { WayagramContext } from '../../../../store/context/WayagramContext';
import WayagramPageProfile from '../../../shared/Modals/Profile/WayagramPageProfile';
import PageFollowers from './PageFollowers';
import Share from '../../../shared/Modals/share';
import avatar2 from '../../../../assets/image/avatar2.svg';
import addFile from '../../../../assets/image/addFile.svg';
import CreatePostModal from '../../../shared/Modals/Post/CreatePost';
import Invite from '../../../shared/Modals/Invite';

export default function Index(props) {
  const [showEditProfile, setShowEditProfile] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const { match } = props;
  const [showReportModal, setShowReportModal] = useState(false);
  const [showReportUModal, setShowReportUModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPageFollowers, setShowPageFollowers] = useState(false);
  const [reportedProfile, setReportedProfile] = useState(null);
  const MomentData = {};
  const { wayagramProfile: loggedInUser } = useContext(WayagramContext);
  const [feeds, setFeeds] = useState([]);
  const [pageFollowersList, setPageFollowersList] = useState([]);
  const [inviteMembers, setInviteMemembers] = useState([]);
  const [pageData, setPageData] = useState({});
  const [moments, setMoments] = useState({ ...MomentData });
  const [showSharePeople, setShowSharePeople] = useState(false);
  const [showInvitePeople, setShowInvitePeople] = useState(false);
  const [selected, setSelected] = useState('');
  const handleGetPage = async () => {
    const pageInfo = await getPageById(loggedInUser.id, match.params.id);
    const pagePosts = await getPagePosts(loggedInUser.id, match.params.id);
    setPageData(pageInfo);
    setFeeds(pagePosts.data);
  };

  const handleGetPageFollowers = async () => {
    const request = await getPageFollowers(match.params.id);
    setPageFollowersList(request);
    setBtnLoading(false);
  };

  const handleOption = (e) => {
    if (e === 'delete') {
      setShowModal(true);
    }
    if (e === 'editProfile') {
      setShowEditProfile(true);
    }
    if (e === 'ReportGroup') {
      setShowReportModal(true);
    }
    if (e === 'invite') {
      setShowInvitePeople(true);
    }
    if (e === 'share') {
      setShowSharePeople(true);
    }
  };

  const handleDelete = async () => {
    showLoader();
    const data = {
      pageId: match.params.id,
      userId: loggedInUser.id,
    };
    const res = await httpDelete(
      `/main/page/admin-delete-page`,
      data,
      customBaseUrl.wayagramUrl
    );
    if (res.status) {
      hideLoader();
      swal('success', res.message, 'success');
      setShowModal(false);
      history.goBack();
    } else {
      hideLoader();
      swal('error', res.message, 'error');
    }
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  const handleInvite = async (invitee) => {
    setBtnLoading(true);
    const invite = await inviteToAPage(
      loggedInUser.id,
      invitee,
      match.params.id
    );
    setBtnLoading(false);
    swal('success', invite.message, 'success');
  };
  const handleGetMoments = async () => {
    try {
      const res = await httpGet(
        `${customBaseUrl.wayagramUrl}/all-posts/get-user-moments?profile_id=${loggedInUser.id}`
      );
      if (res.status) {
        setMoments({ ...moments, ...res.data.data });
      }
      return {};
    } catch (error) {
      return error.message;
    }
  };

  useEffect(() => {
    if (loggedInUser.id) {
      handleGetPage();
      handleGetPageFollowers();
      handleGetMoments();
    }
  }, [loggedInUser.id, match.params.id]);

  // if (!profileData || !feeds.length) {
  //   return <Loader />;
  // }
  return (
    <Layout route={props} currentPage="Page">
      {console.log('feed', feeds)}
      <div className="dashboard-two-col-wrap wayagram-div">
        <div className="dasboard-col-one">
          {pageData ? (
            <>
              <MomentWrapper className="row m-2">
                <div className="moment-header momentHead">Moments</div>
                {Object.keys(moments).length ? (
                  <Carousel
                    responsive={responsive}
                    ssr
                    autoPlay
                    infinite={false}
                    autoPlaySpeed={10000}
                    keyBoardControl
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    // beforeChange={() => setState(true)}
                    // afterChange={() => setState(false)}
                    // containerClass="first-carousel-container container"
                  >
                    {moments &&
                      Object.values(moments).map((data) => (
                        <div
                          key={data.id}
                          className="moment-card"
                          style={{
                            backgroundImage:
                              data.type === 'image'
                                ? `url(${data.content})`
                                : 'none',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            textAlign: 'center',
                            backgroundColor:
                              data.type !== 'image' && '#000000a8',
                            color: data.type !== 'image' && '#fff',
                          }}
                        >
                          <div>
                            {data.type === 'text' ? (
                              <p className="">{data.content}</p>
                            ) : (
                              ''
                            )}
                            <div className="moment-inner">
                              <img
                                className="moment-img"
                                src={data?.Profile?.avatar || imgPlaceholder}
                                alt="Profile"
                              />
                              <h6>{data.userName}</h6>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                ) : (
                  <div>
                    <Skeleton height={150} width={150} />
                  </div>
                )}
              </MomentWrapper>

              <div className="wayagram-group-top p-4">
                {/* <p className="momentHead">Moments</p> */}
                <div className="d-flex">
                  <div className="">
                    {pageData.imageUrl ? (
                      <img
                        src={pageData?.imageUrl}
                        alt="cake"
                        style={{ height: '130px' }}
                      />
                    ) : (
                      <Skeleton height={130} width={130} />
                    )}
                  </div>
                  <div
                    className=""
                    style={{ paddingLeft: '35px', width: '80%' }}
                  >
                    <div className="top-content d-flex justify-content-between align-items-center">
                      <h3>
                        {pageData?.title || (
                          <Skeleton height={20} width={102} />
                        )}
                      </h3>
                      {pageData?.userId === loggedInUser.id ? (
                        <div className="option-div ml-auto my-auto d-flex align-items-center">
                          <div
                            className="dot-3x"
                            style={{ position: 'relative' }}
                          >
                            <div className="option1">
                              <img src={search} alt="Search" />
                            </div>
                            <CustomDropdown
                              id={12}
                              direction="horizontal"
                              handleOption={(e) => {
                                handleOption(e);
                              }}
                              options={[
                                {
                                  name: 'Public',
                                  type: 'public',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Edit Profile',
                                  type: 'editProfile',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Invite',
                                  type: 'invite',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Share',
                                  type: 'share',
                                  id: 'pageId',
                                },

                                {
                                  name: 'Delete',
                                  type: 'delete',
                                  id: 'pageId',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="option-div ml-auto my-auto d-flex">
                          <div
                            className="dot-3x"
                            style={{ position: 'relative' }}
                          >
                            <div className="option1">
                              <img src={search} alt="Search" />
                            </div>
                            <CustomDropdown
                              id={12}
                              direction="horizontal"
                              handleOption={(e) => {
                                handleOption(e);
                              }}
                              options={[
                                {
                                  name: 'Mute',
                                  type: 'mute',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Report',
                                  type: 'ReportGroup',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Invite',
                                  type: 'invite',
                                  id: 'pageId',
                                },
                                {
                                  name: 'Share',
                                  type: 'share',
                                  id: 'pageId',
                                },

                                {
                                  name: 'Exit',
                                  type: 'exit',
                                  id: 'pageId',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="text-muted">
                      {truncate(pageData.description, 50) || (
                        <Skeleton
                          height={60}
                          style={{
                            width: '75%',
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex member-img-grp mt-3">
                  <img className="img-1" src={cakeImage} alt="members" />
                  <img className="img-2" src={cakeImage} alt="members" />
                  <img className="img-3 me-4" src={cakeImage} alt="members" />
                  <p
                    className="my-auto ms-5 ps-5"
                    onClick={() => setShowPageFollowers(true)}
                  >
                    {`${pageData?.numberOfFollowers || 0} follower${
                      pageData?.numberOfFollowers > 1 ? 's' : ''
                    }`}
                  </p>
                </div>
              </div>
              {pageData?.isFollowing && pageData?.isAdmin && (
                <div className="px-4 pt-3 pb-4  d-flex align-items-center border">
                  <img
                    src={loggedInUser.avatar || avatar2}
                    alt="Avatar"
                    className="me-3"
                    style={{
                      width: '42px',
                      height: '42px',
                    }}
                  />
                  {/* {profileData.isUserOnMute === false ? ( */}
                  <div
                    className="d-flex create-post"
                    onClick={() => {
                      setShowCreatePost(true);
                    }}
                  >
                    <div> Create a page post</div>
                    <div>
                      <img src={addFile} alt="addFile" />
                    </div>
                  </div>
                  {/* ) : (
                        <div className="d-flex create-post">
                          <div>You have been muted by the admin...</div>
                        </div>
                      )} */}
                </div>
              )}
              <div className=" mt-5">
                {feeds.length ? (
                  feeds.map((post) => (
                    <GramCard
                      key={Math.random()}
                      data={post}
                      handleDeletePost={() => console.log('delete')}
                      showPostModal={() => console.log('delete')}
                      handleLikePost={() => console.log('like')}
                      addBookmark={() => console.log('bookmark')}
                      blockUser={() => console.log('block')}
                      handleFollowUser={() => console.log('follow')}
                      fromLocation="page"
                    />
                  ))
                ) : (
                  <div className="text-center my-5  w-100 border p-5">
                    No Posts
                  </div>
                )}

                {showEditProfile ? (
                  <WayagramPageProfile
                    showModal={showEditProfile}
                    hideModal={setShowEditProfile}
                    pageData={pageData}
                  />
                ) : (
                  ''
                )}

                {showModal ? (
                  <ConfirmModal
                    showModal={showModal}
                    hideModal={setShowModal}
                    title="confirm Delete"
                    description="are you sure you want to delete this page?"
                    handleSubmit={handleDelete}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>

        <RightSidebar />
      </div>

      {showReportModal ? (
        <Report
          showModal={showReportModal}
          hideModal={setShowReportModal}
          title="Report Page"
          reported={pageData}
          type="Page"
          reporter={loggedInUser}
        />
      ) : (
        ''
      )}
      {showReportUModal ? (
        <Report
          showModal={showReportUModal}
          hideModal={setShowReportUModal}
          title="Report Page User"
          reported={reportedProfile}
          type="Page"
          reporter={loggedInUser}
        />
      ) : (
        ''
      )}
      {showPageFollowers ? (
        <PageFollowers
          showModal={showPageFollowers}
          hideModal={setShowPageFollowers}
          members={pageFollowersList}
          btnLoading={btnLoading}
          selected={selected}
          setSelected={setSelected}
          profileData={pageData}
          setShowReportModal={setShowReportUModal}
          setReportedProfile={setReportedProfile}
        />
      ) : (
        ''
      )}

      {showSharePeople ? (
        <Share
          showModal={showSharePeople}
          hideModal={setShowSharePeople}
          title="Share"
          description="Invite your friend to join you on wayagram"
          url={`${process.env.REACT_APP_FRONTEND}/wayagram-page/${match.params.id}`}
          text={`${process.env.REACT_APP_FRONTEND}/wayagram-page/${match.params.id}`}
        />
      ) : (
        ''
      )}
      {showCreatePost ? (
        <CreatePostModal
          showModal={showCreatePost}
          hideModal={setShowCreatePost}
          id="create-post-modal"
          mode="create"
          type="page"
          pageId={pageData.id}
        />
      ) : (
        ''
      )}
      {showInvitePeople ? (
        <Invite
          showModal={showInvitePeople}
          hideModal={setShowInvitePeople}
          btnLoading={btnLoading}
          setInviteMembers={setInviteMemembers}
          inviteMembers={inviteMembers}
          handleInvite={handleInvite}
          selected={selected}
          setSelected={setSelected}
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
