import React, { useState } from "react";
import { Container, Row, Col, Stack } from "react-bootstrap";
import Layout from '../../../components/shared/Layout';
import { AiFillEye, AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import {
  searchRequestedTerminalsByParam,
  terminalReqStats,
  viewAllRequestedTerminals,
} from "../../plugins/urls";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import {
  terminalFilters,
  terminalReqTableCols,
} from "../../utilities/dummyData";
import IconButton from "@material-ui/core/IconButton";
import CircularLoader from "../../components/SubmitLoader/CircularLoader";
import EllipsisLoader from "../../components/SubmitLoader/ElipseLoader";
import { apiCall } from "../../utilities/apis";
import Cards from "../../components/Cards/Cards";
import { terminalRequestCard } from "./data";

const TerminalRequest = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);

  const [state, setState] = useState({
    totalTerminalRequest: "",
    totalApproved: "",
    totalRejected: "",
  });

  const getTerminalReqStats = () => {
    apiCall({
      method: "get",
      setLoading: setLoadingStats,
      url: terminalReqStats,
      noError: true,
      success: (data) => {
        const { totalTerminalRequest, totalApproved, totalRejected } =
          data.respBody;
        setState({
          totalTerminalRequest,
          totalApproved,
          totalRejected,
        });
      },
    });
  };

  const actionTerminal = (terminal) => navigate(`/terminals/${terminal.id}`);

  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: viewAllRequestedTerminals,
    includeMeta: true,
    filters: terminalFilters,
    filterUrl: searchRequestedTerminalsByParam,
    showSearchIcon: true,
    csvName: "WAYA_POS_REQUESTED_TERMINALS_",
    dateFilterMenuName: "date",
    cols: terminalReqTableCols(
      actionTerminal,
      <AiFillEye size={20} color="#FF6700" />
    ),
    filterCallMethod: 3,
    data: { pageNo: 0 },
    dataAddOn: {
      userId: user?.id,
    },
    // deps: [walletNo],
    startCb: () => {
      getTerminalReqStats();
    },
  });

  return (
    <Layout title="Terminal Requests">
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        {loading ? (
          <EllipsisLoader />
        ) : (
          <Stack>
            <IconButton
              style={{ width: "fit-content" }}
              onClick={() => navigate("/terminals")}
              title="Go back to terminals"
            >
              <AiOutlineArrowLeft />
            </IconButton>
            <div className="d-flex justify-content-between filter-contents align-items-center">
              <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>
              <div className="d-flex justify-content-start align-items-center mt-md-1 mt-xl-0">
                {tableData?.content?.length > 0 && exportButton}
              </div>
            </div>
          </Stack>
        )}
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            {Object.entries(state).map(([key, val]) => {
              const { name, color, textColor } = terminalRequestCard[key];
              return (
                <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
                  <Cards
                    noCurrency={true}
                    cardTitle={name}
                    value={val ?? 0}
                    color={color}
                    textColor={textColor}
                  />
                </Col>
              );
            })}
          </Row>
        )}
        {loading ? <CircularLoader /> : simpleTable}
      </Container>
    </Layout>
  );
};

export default TerminalRequest;
