import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Cards from "../../components/Cards/Cards";
import { VscAdd } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import Layout from '../../../components/shared/Layout';

import {
  allTerminals,
  searchAllTerminalsByParam,
  searchRequestedTerminalsByParam,
  terminalStats,
} from "../../plugins/urls";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import { terminalFilters, terminalTableCols } from "../../utilities/dummyData";
import { apiCall } from "../../utilities/apis";
import CircularLoader from "../../components/SubmitLoader/CircularLoader";
import { AiFillEye } from "react-icons/ai";
import useUser from "../../components/Hooks/useUser";
import EllipsisLoader from "../../components/SubmitLoader/ElipseLoader";

const Terminal = () => {
  const { user } = useUser();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);

  const [state, setState] = useState({
    activeTerminals: "",
    damagedTerminals: "",
    suspendedTerminals: "",
    totalTerminals: "",
  });

  const {
    activeTerminals,
    damagedTerminals,
    suspendedTerminals,
    totalTerminals,
  } = state;

  const getTerminalStats = () => {
    apiCall({
      method: "get",
      setLoading: setLoadingStats,
      url: terminalStats,
      noError: true,
      success: (data) => {
        const {
          activeTerminals,
          damagedTerminals,
          suspendedTerminals,
          totalTerminals,
        } = data.respBody;
        setState((state) => ({
          activeTerminals,
          suspendedTerminals,
          totalTerminals,
          damagedTerminals,
        }));
      },
    });
  };

  const actionTerminal = (terminal) => navigate(`/terminals/${terminal.id}`);

  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: allTerminals,
    includeMeta: true,
    filters: terminalFilters,
    filterUrl: searchAllTerminalsByParam,
    showSearchIcon: true,
    csvName: "WAYA_POS_TERMINALS_",
    dateFilterMenuName: "date",
    cols: terminalTableCols(
      actionTerminal,
      <AiFillEye size={20} color="#FF6700" />
    ),
    filterCallMethod: 3,
    dataAddOn: {
      paramList: [
        {
          userid: user?.id,
        },
      ],
    },
    // deps: [walletNo],
    startCb: () => {
      getTerminalStats();
    },
  });

  return (
    <Layout title="Terminals">
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>

          <div className="d-flex justify-content-start align-items-center mt-md-1 mt-xl-0">
            {tableData?.content?.length > 0 && exportButton}
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="request-button"
                onClick={() => {
                  navigate("/add-terminal");
                }}
              >
                <VscAdd color={"#fff"} className="mr-5" />
                Request new terminal
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="request-button"
                onClick={() => {
                  navigate("/terminal-requests");
                }}
              >
                View terminal requests
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Issued Terminal"
                value={totalTerminals}
                color="text-darker"
                textColor="text-darker"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Active Terminals"
                value={activeTerminals}
                color="text-sharp-green"
                textColor="text-sharp-green"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Damaged Terminals"
                value={damagedTerminals}
                color="text-semi-dark"
                textColor="text-semi-dark"
              />
            </Col>
            <Col xs={12} md={6} xl={3} className="mt-2 mt-xl-0">
              <Cards
                noCurrency={true}
                cardTitle="Total Suspended Terminals"
                value={suspendedTerminals}
                color="text-red"
              />
            </Col>
          </Row>
        )}

        <div>{loading ? <CircularLoader /> : simpleTable}</div>
      </Container>
    </Layout>
  );
};

export default Terminal;
