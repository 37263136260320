import format from 'date-fns/format';
import { useCallback, useEffect, useState } from 'react';
import { SVG } from '../../assets/svg';
import { apiCall } from '../../utilities/apis';
import SimpleSearchBox from '../UI/SimpleSearchBox';
//import {transactionFilters, transactionTableCols} from "../../utilities/dummyData";
import Button from '@material-ui/core/Button';
import { TextField } from '@mui/material';
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { IoFilterOutline } from 'react-icons/io5';
import { ExportToExcel } from '../../utilities/downloadExcelFron';
import { camelToString, sentenceCaps } from '../../utilities/stringOperations';
import SimpleDateFilter from '../UI/DateFilter';
import SimpleTable from '../UI/Tables/Table';

const { exportCopy } = SVG;

const useSimpleTable = ({
  setLoading,
  passDate = true,
  isRange = true,
  url,
  filterUrl,
  dataAddOn,
  method = 'post',
  startCb,
  filters,
  cols,
  filterCallMethod,
  deps = [],
  depCb,
  filterMethod = 'post',
  csvName,
  showSearchIcon,
  autoSearch,
  dateFilterMenuName,
  dateName = '',
  parseString = false,
  data,
  includeMeta = true,
  getData,
  from,
  to,
}) => {
  const [formData, setFormData] = useState({
    pageSize: 20,
    pageNo: 1,
    ...data,
  });

  const [tableData, setTableData] = useState({});
  const [filter, setFilter] = useState(filters[0]);
  const [searchTerm, setSearchTerm] = useState('');

  const controller = new AbortController();

  const getAllLogs = useCallback(() => {
    const data = {
      ...formData,
      ...dataAddOn,
    };
    let urlString = parseString
      ? `${url}?pageSize=${formData.pageSize}&pageNo=${formData.pageNo}${
          typeof parseString === 'string' ? parseString : ''
        }`
      : url;
    apiCall({
      url: urlString,
      success: (data) => {
        setTableData(getData?.(data) ?? data?.data ?? data);
      },
      setLoading,
      data,
      method,
      defaultMeta: includeMeta,
    });
    typeof depCb === 'function' && depCb();
  }, [formData, ...deps]);

  const filterTerm = (value, field) => {
    const meth1 = () =>
      apiCall({
        url: `${filterUrl}?field=${field}&value=${value}&pageSize=${formData.pageSize}&pageNo=${formData.pageNo}`,
        success: (data) => {
          setTableData(data);
          setSearchTerm('');
        },
        setLoading,
        method: filterMethod,
        defaultMeta: includeMeta,
      });

    const meth2 = () =>
      apiCall({
        url: filterUrl,
        success: (data) => {
          setTableData(data);
          setSearchTerm('');
        },
        setLoading,
        data: {
          ...formData,
          paramList: [
            {
              [field]: value,
            },
          ],
        },
        method: filterMethod,
        defaultMeta: includeMeta,
      });
    const meth3 = () =>
      apiCall({
        url: filterUrl,
        success: (data) => {
          setTableData(data);
          setSearchTerm('');
        },
        setLoading,
        data: {
          ...formData,
          field,
          value,
        },
        method: filterMethod,
        defaultMeta: includeMeta,
      });
    const meths = ['', meth1, meth2, meth3];

    return filterCallMethod ? meths[filterCallMethod]() : meth1();
  };

  useEffect(() => {
    if (dateName === filter) {
      filterTerm(format(formData?.date, 'yyyy/mm/dd'), filter);
    }
    return () => controller.abort();
  }, [formData?.date]);

  useEffect(() => {
    if (searchTerm !== '' && filter !== dateFilterMenuName)
      filterTerm(searchTerm, filter);
    return () => controller.abort();
  }, [filter, searchTerm]);

  /*
    update with getAllLogs for date changes, page no, and no. of items per page otherwise,
    filter base on search term if filter param is not dateFilterMenuName
    * */

  useEffect(() => {
    if (searchTerm !== '' && filter !== dateFilterMenuName)
      filterTerm(searchTerm, filter);
    else getAllLogs(formData);
    return () => controller.abort();
  }, [getAllLogs]);

  useEffect(() => {
    typeof startCb === 'function' && startCb();
    let stateCopy = { ...formData };
    if (dateName !== '') stateCopy.date = new Date();
    if (!passDate || !isRange) {
      delete stateCopy.from;
      delete stateCopy.to;
      setFormData(stateCopy);
    }
    return () => controller.abort();
  }, []);

  //business logic

  const setEntity = (name, value) =>
    setFormData({ ...formData, [name]: value });
  const setDateFilter = (from, to) =>
    setFormData((prevState) => ({ ...prevState, from, to }));
  const clearSearch = () => {
    setSearchTerm('');
  };
  const clearDate = () => {
    setFormData({
      pageSize: 20,
      pageNo: 1,
      from: new Date('1/1/2022'),
      to: new Date(),
      ...data,
    });
  };

  const handleDateChange = (date) =>
    setFormData({ ...formData, [dateName]: date });

  // temps
  const dateFilter = (
    // <LocalizationProvider dateAdapter={AdapterDateFns}>
    // <DesktopDatePicker
    //   inputFormat="yyyy/MM/dd"
    //   name="date"
    //   value={formData.date}
    //   onChange={handleDateChange}
    //   renderInput={(params) => <TextField variant="standard" {...params} />}
    //   date={formData.date}
    // />
    // </LocalizationProvider>
    <input type="date" value={formData.date} onChange={handleDateChange} />
  );
  const simpleDateFilter =
    dateName === '' ? (
      <SimpleDateFilter setDateFilter={setDateFilter} clearSearch={clearDate} />
    ) : (
      dateFilter
    );

  const searchPane = (
    <div className="d-flex">
      <SimpleSearchBox
        items={filters}
        setSelected={setFilter}
        selected={filter}
        icon={<IoFilterOutline size={15} style={{ marginRight: 15 }} />}
        filter
        camel
        simpleDateFilter={simpleDateFilter}
        dateFilterMenuName={dateName === '' ? dateFilterMenuName : dateName}
        autoSearch={autoSearch}
        handleSearch={setSearchTerm}
        clearSearch={clearSearch}
        showSearchIcon={showSearchIcon}
        placeholder={
          filter === 'Filter'
            ? filter
            : `Search by ${sentenceCaps(camelToString(filter))}`
        }
      />
    </div>
  );

  const exportButton = (
    <div className="d-flex justify-content-center align-items-center ">
      <Button
        variant="outlined"
        onClick={() =>
          ExportToExcel({
            apiData: tableData?.content || tableData,
            fileName: csvName,
          })
        }
        style={{ backgroundColor: 'var(--thirdColor)', color: '#fff' }}
      >
        <img src={exportCopy} alt="" />
        <div className="ms-2"> Export data</div>
      </Button>
    </div>
  );

  const simpleTable = (
    <div className="data-table mt-40">
      <SimpleTable cols={cols} tableData={tableData} setEntity={setEntity} />
    </div>
  );

  return {
    setEntity,
    tableData,
    setDateFilter,
    clearSearch,
    filter,
    setFilter,
    searchPane,
    exportButton,
    simpleTable,
    simpleDateFilter,
    setTableData,
  };
};
export default useSimpleTable;
