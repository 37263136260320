import React, { useContext, useState } from 'react';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { NotificationManager } from 'react-notifications';
import { decodeToken } from 'react-jwt';
import { Dropdown, Avatar, Badge, Button, Space } from 'antd';
import NotificationDrawer from './notificationDrawer';
import { AddMoreIcon, Notification } from '../Icons';
import { ProfileContext } from '../../../store/context/ProfileContext';
import { NotificationContext } from '../../../store/context/NotificationContext';
import envConfig from '../../../utils/envConfig';
import { customBaseUrl, httpPost } from '../../../action/http';
import { hideLoader, showLoader } from '../../../utils/loader';
import { encrypt } from '../../../utils/helper';
import CreateNewAccount from '../Modals/CeateNewAccount';
// import envConfig from '../../../utils/envConfig';
// import { PaymentContext } from '../../../store/context/PaymentContext';

const Actions = () => {
  const [visible, setVisible] = useState(false);
  const { allNotification } = useContext(NotificationContext);
  return (
    <>
      <Button type="link" onClick={() => setVisible(true)}>
        <Badge
          count={
            allNotification
              ? [...allNotification.filter((el) => el.read !== true)].length
              : 0
          }
          size="small"
        >
          <Notification />
        </Badge>
      </Button>
      <NotificationDrawer visible={visible} onClose={setVisible} />
    </>
  );
};

export { Actions };
const handleSwitch = async (e) => {
  showLoader();
  const res = await httpPost(
    `/api/v1/profile/makeAccountDefault/${e?.user?.id}?id=${e?.id}`,
    {},
    customBaseUrl.authUrl
  );

  if (res?.status) {
    hideLoader();
    // setAccountList(res?.data)
    handleSubmit();
    console.log('gggg', res);
  } else {
    hideLoader();
    swal(res?.message);
  }
};

const handleSubmit = async () => {
  showLoader();
  const res = await httpPost(
    '/api/v1/auth/validate-user',
    {},
    customBaseUrl.authUrl
  );
  if (res?.status) {
    if (!res.data.pinCreated) {
      localStorage.setItem('firstLogin', true);
      // setShowPin(true);
    }
    // navigate('/products');
    console.log('decodedTokenhiiiii');
    const token = localStorage.getItem('token');
    const decodedToken = decodeToken(token);
    const expDate = new Date(decodedToken?.exp * 1000);
    const newDate = new Date();
    localStorage.setItem('userId', res?.data?.userId);
    const isPinCreated = localStorage.getItem('isPinCreated');
    console.log('userId', res?.data?.userId);
    localStorage.setItem('userDetails', res?.data);
    // if (expDate > newDate) {
    const encoded = encrypt(token);
    if (res.data?.profileSubscription?.corporate) {
      window.location.href = `${envConfig.corporateAppUrl}/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
      //  window.location.href = `http://localhost:3000/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
    } else {
      window.location.href = `${envConfig.personalAppUrl}/login?client=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
      // window.location.href = `http://localhost:3000/login?from=web&iv=${encoded.iv}&token=${encoded.encryptedData}&userId=${res?.data?.id}&pinCreated=${isPinCreated}&profileId=${res?.data?.profileSubscription?.profileId}`;
    }
  } else {
    setLoading(false);
    swal('Oops!', res?.message ?? 'error');
  }
};
const ProfileMenu = () => {
  const {
    referralCode: referenceCode,
    userDetails: { email, lastName, firstName, merchantId },
    profile: { profileImage },
    BusinessName,
    subscriptions,
    fetchSubscription,
  } = useContext(ProfileContext);
  const [showAddAccount, setShowAddAccount] = useState(false);

  // const {
  //   receivedRequest: { request },
  // } = useContext(PaymentContext);

  const copyIdToClipboard = (merchant) => {
    navigator.clipboard.writeText(merchant ? merchantId : referenceCode);

    NotificationManager.success(
      'Referal Code copied successful',
      'Copied!',
      3000
    );
    // swal('Copied', `MerchantId copied successful`, 'success');
  };

  const logout = () => {
    localStorage.clear();
    window.location.href = envConfig.login;
  };

  const menu = (
    <div className="user-menu">
      <div className="user-menu-div">
        <Avatar
          className="avatar"
          shape="circle"
          size="large"
          icon={<UserOutlined />}
          src={profileImage}
        />

        <div>
          <Space direction="horizontal">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 40,
                justifyContent: 'space-between',
              }}
            >
              {BusinessName ? (
                <p>{BusinessName}</p>
              ) : (
                <p>{`${firstName || ''} ${lastName || ''}`}</p>
              )}
              <span>{merchantId}</span>
              <span
                style={{
                  height: '40px',
                }}
              >
                {referenceCode}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: 20,
              }}
            >
              {/* <Button type="link" onClick={() => copyIdToClipboard(true)}>
                Copy Merchant Code
              </Button> */}
              <Button type="link" onClick={() => copyIdToClipboard(false)}>
                Copy Referal Code
              </Button>
            </div>
          </Space>
        </div>
      </div>
      {subscriptions?.length > 1 && (
        <div className="col">
          <hr />
          <span style={{ marginLeft: 10 }}> Other profiles </span>
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            {subscriptions?.length > 0 &&
              Object.keys(subscriptions)?.map((e) => (
                <div
                  onClick={() => handleSwitch(subscriptions[e])}
                  key={e}
                  className="col"
                  style={{ marginLeft: 10, marginTop: 10 }}
                >
                  {!subscriptions[e]?.isDefaultAccount && (
                    <UserOutlined style={{ fontSize: 24, marginRight: 10 }} />
                  )}
                  {!subscriptions[e]?.isDefaultAccount &&
                    (subscriptions[e]?.profile?.otherDetails
                      ?.organisationName ??
                      subscriptions[e]?.profile?.firstName +
                        ' ' +
                        subscriptions[e]?.profile?.surname)}
                </div>
              ))}
          </div>
        </div>
      )}
      <div onClick={() => setShowAddAccount(true)}>
        <PlusOutlined /> Create new account
      </div>
      <div className="user-menu-down">
        <p>Login as</p>
        <p>{email}</p>
        <button type="button" onClick={logout}>
          Log Out
        </button>
      </div>
      {showAddAccount ? (
        <CreateNewAccount
          showModal={showAddAccount}
          hideModal={setShowAddAccount}
          fetchSubscription={fetchSubscription}
        />
      ) : (
        ''
      )}
    </div>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="user-dropdown">
        <Badge count={0}>
          <Avatar
            className="avatar"
            shape="circle"
            size="large"
            icon={<UserOutlined />}
            src={profileImage}
          />

          <span>
            {`${BusinessName || `${firstName || ''} ${lastName || ''}`}`}
          </span>
        </Badge>
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
