import Button from '@material-ui/core/Button';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utilities/objectOperations';
import { ReactComponent as Map } from '../../assets/icons/location.svg';
import { AddTerminalContext } from '../../pages/Terminals/AddTerminal';
import Divider from '../Divider/Divider';
import './select.scss';

const Address = () => {
  const { otherDetails, posUserProfile, ...user } = getUser();
  const navigate = useNavigate();
  const {
    state: { deliveryAddress, organizationName },
  } = useContext(AddTerminalContext);
  return (
    <div className="address mt-20">
      <h4 className="text-grey fs-20 fw-700">Delivery Address</h4>
      <div className="d-flex justify-content-between mt-20 mb-15">
        <div className="d-flex justify-content-start">
          <Map className="mt-02" />
          <div className="ml-30">
            <h4 className="text-darker fs-16 fw-700">{organizationName}</h4>
            <h4 className="text-darker fs-16 fw-700">{deliveryAddress}</h4>
            <h4 className="text-grey fs-16 fw-400">{user.phoneNumber}</h4>
          </div>
        </div>

        <div>
          <Button
            className="change-button"
            onClick={() => navigate('/settings?update-profile')}
          >
            Change
          </Button>
        </div>
      </div>

      <Divider />

      <Row className="mt-20">
        <Col lg={4}>
          <h4 className="text-grey fs-16 fw-400">
            Once you click on the continue button below, you will proceed to
            make full payment for your POS order. <br />
            {/*<br /> Part payment will require a minimum of 50% upfront and the 50% is to be paid over the 30 days.*/}
          </h4>
        </Col>
      </Row>
    </div>
  );
};

export default Address;
