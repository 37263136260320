import React, { useState } from "react";
import Layout from '../../../components/shared/Layout';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cards from "../../components/Cards/Cards";
import SettlementCard from "../../components/SettlementComponents/SettlementCard";
import { Withdrawal } from "./Withdrawal";
import "./styles.scss";
import { ReactComponent as Lock } from "../../assets/icons/lock.svg";
import {
  allSettlement,
  getSettlementByParam,
  settlementStatistics,
  settlementStats,
} from "../../plugins/urls";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import {
  settlementFilters,
  settlementTableCols,
} from "../../utilities/dummyData";
import { apiCall } from "../../utilities/apis";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import CircularLoader from "../../components/SubmitLoader/CircularLoader";
import { Button } from "@mui/material";
import { CustomIcon } from "../../utilities/CustomIcon";
import Modal from "../../components/Modal";

import "./styles.scss";

const Settlement = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();

  const [showWithdrawal, setShowWithdrawal] = useState(false);

  const [loading, setLoading] = useState(false);

  const tableAction = (terminal) => navigate(`/settlement/${terminal.id}`);
  const Title = () => {
    return (
      <div>
        <p>{"Withdraw payment to your bank"}</p>
        <p>{"Kindly note that N100 fixed charge will be debited"}</p>
      </div>
    );
  };

  /* custom hooks */
  const {
    tableData,
    simpleTable,
    exportButton,
    searchPane,
    simpleDateFilter,
    filter,
  } = useSimpleTable({
    setLoading,
    url: allSettlement,
    method: "get",
    includeMeta: true,
    filters: settlementFilters,
    filterMethod: "get",
    filterUrl: getSettlementByParam,
    showSearchIcon: true,
    csvName: "WAYA_POS_SETTLEMENTS_",
    dateFilterMenuName: "date",
    cols: settlementTableCols(
      tableAction,
      <AiFillEye size={20} color="#FF6700" />
    ),
    dataAddOn: {
      userid: user?.id,
    },
    // deps: [walletNo],
    startCb: () => {
      getSettlementStats();
      getSettlementStatistics();
    },
  });

  const [state, setState] = useState({
    settled: "",
    failed: "",
    pending: "",
  });

  const {
    settled,
    failed,
    pending,
    lastSettlementAmount,
    nextSettlementAmount,
  } = state;

  const getSettlementStats = () =>
    apiCall({
      method: "post",
      url: settlementStats,
      setLoading,
      noError: true,
      defaultMeta: true,
      success: (data) => {
        const { failed, pending, settled } = data;
        setState((state) => ({
          ...state,
          failed,
          pending,
          settled,
        }));
      },
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });

  const getSettlementStatistics = () =>
    apiCall({
      method: "get",
      url: settlementStatistics,
      setLoading,
      noError: true,
      defaultMeta: true,
      success: (data) => {
        const {
          totalActualAmount,
          totalSettlementAmount,
          nextSettlementAmount,
          lastSettlementAmount,
        } = data;
        setState((state) => ({
          ...state,
          totalActualAmount,
          totalSettlementAmount,
          nextSettlementAmount,
          lastSettlementAmount,
        }));
      },
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });

  const closeModal = () => setShowWithdrawal(false);

  return (
    <Layout title="Settlements">
      <Container>
        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
            <div>{filter === "Date" ? simpleDateFilter : searchPane}</div>

            <div className="d-flex justify-content-center align-items-center ">
              <Button
                id="button-wallet"
                size={"medium"}
                onClick={() => setShowWithdrawal(true)}
              >
                <CustomIcon IconComp={Lock} style={{ marginRight: "4px" }} />
                {"Withdraw"}
              </Button>
              <span
                style={{ width: "120px", fontSize: "10px" }}
                className={"text-red pr-1"}
              >
                {"Kindly note N100 fixed charge will be deducted"}
              </span>
            </div>

            {tableData?.content?.length > 0 && exportButton}
          </div>
        </div>

        <Row className="mt-40">
          <Col xs={12} md={6} xl={4} className="my-2 mt-xl-0">
            <Cards
              cardTitle="Last Settlement"
              value={
                lastSettlementAmount?.settledAmount?.toLocaleString() || "0.00"
              }
              date={
                lastSettlementAmount?.createdAt
                  ? `Settled on ${moment(
                      lastSettlementAmount?.createdAt
                    )?.format("MMMM Do YYYY, h:mm a")}`
                  : "No settlement yet"
              }
              color="text-orange"
              textColor="text-darker"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="my-2 mt-xl-0">
            <Cards
              cardTitle="Next Settlement"
              value={
                nextSettlementAmount?.settledAmount?.toLocaleString() || "0.00"
              }
              date={
                nextSettlementAmount?.createdAt
                  ? `Settled on ${moment(
                      nextSettlementAmount?.createdAt
                    )?.format("MMMM Do YYYY, h:mm a")}`
                  : "No settlement yet"
              }
              color="text-orange"
              textColor="text-darker"
            />
          </Col>
          <Col xs={12} md={6} xl={4} className="my-2 mt-xl-0">
            <SettlementCard
              settled={settled}
              pending={pending}
              failed={failed}
            />
          </Col>
        </Row>

        <div className=" mt-40">
          {loading ? <CircularLoader /> : simpleTable}
        </div>
        <Modal show={showWithdrawal} onclick={closeModal} title={<Title />}>
          <Withdrawal onClose={closeModal} />
        </Modal>
      </Container>
    </Layout>
  );
};

export default Settlement;
