import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Slide, toast } from 'react-toastify';
import axios from 'axios';

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    if (apiData.length === 0)
      return toast.error('Cannot export empty data', {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${fileName}_${new Date()} ${fileExtension}`);
  };
  return exportToCSV(apiData, fileName);
};

export const exportFile = ({
  data,
  url,
  method = 'post',
  setLoading,
  success,
  error,
}) => {
  let authToken = null;
  // if (typeof window !== "undefined") {
  if (localStorage.getItem('userDetails')) {
    // console.log(JSON.parse(localStorage.getItem('userDetails')))
    const { token } = JSON.parse(localStorage.getItem('userDetails'));
    authToken = token;
  }

  setLoading && setLoading(true);
  axios({
    method,
    url,
    data,
    header: {
      ContentType: 'multipart/form-data',
      Authorization: authToken,
    },
  })
    .then((res) => {
      // console.log(res.data)
      setLoading && setLoading(false);

      if (res.data.status === true) {
        success && success(res?.data);
      } else {
        error && error(res.data);
        toast.error(`${res?.data?.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      error && error(err?.response?.data);
      setLoading && setLoading(false);
      toast.error(`${err?.response?.data?.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};
