import moment from 'moment';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { FaTimes } from 'react-icons/fa';
import './styles.scss';

const SimpleDateFilter = ({ setDateFilter, clearSearch }) => {
  const [open, setOpen] = useState(null);
  const [dateString, setDateString] = useState('Date');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      setDateFilter(start, end);
      setDateString(
        `From ${moment(start).format('MMM Do YY')} to ${moment(end).format(
          'MMM Do YY'
        )}`
      );
    }
  };
  const handleClose = () => {
    setStartDate(new Date());
    setEndDate(null);
    setOpen(false);
  };
  const handleClear = () => {
    handleClose();
    setDateString('Date');
    // setDateFilter('', '');
    clearSearch?.();
  };

  return (
    <>
      {/* <Button
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        className="no-btn w-100 border"
      >
        <div className="d-flex justify-content-between collapse-btn">
          <div className="pe-3 align-content-start text-muted w-100 ">
            {dateString}
          </div>

          <div className="d-flex">
            <span>
              {" "}
              <FaCaretDown />
            </span>
            {dateString !== "Date" && (
              <span onClick={handleClear} className="ps-1">
                <FaTimes className="text-orange" />
              </span>
            )}
          </div>
        </div>
      </Button>
      <div>
        <FadeMenu
          temp={
            <Card body style={{ width: "400px" }}>
              <div className="d-flex justify-content-center align-items-center filter-search ml-22">
                <div className="input-container d-flex justify-content-center align-items-center">
                  <DatePicker
                    dateFormat="dd-MM-yyyy"
                    onChange={onChange}
                    isClearable
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    className="input"
                    wrapperClassName="datePicker"
                    selectsRange
                    inline
                    onClickOutside={handleClose}
                  />
                </div>
              </div>
            </Card>
          }
          open={Boolean(open)}
          handleClose={() => setOpen(null)}
          anchorEl={open}
        />
      </div> */}
      <div className="dateContainer">
        <div className="input-item">
          <label>{'From'}</label>
          <input type={'date'}></input>
        </div>
        <div className="input-item">
          <label>{'to'}</label>
          <input type={'date'}></input>
        </div>
      </div>
      <div>
        <FaTimes />
      </div>
    </>
  );
};

export default SimpleDateFilter;
