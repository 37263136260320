import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  accountList,
  personalUpdate,
  walletDetails,
} from '../../../../plugins/urls';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Slide, toast } from 'react-toastify';

export const PaymentPreference = () => {
  const [accountDt, setAccountDt] = useState({ account: [] });
  const [loading, setLoading] = useState(false);

  const [formDt, setFormDt] = useState({
    primaryAcc: null,
    settlementType: null,
  });

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormDt((dt) => ({ ...dt, [name]: value }));
  };
  const getAccountList = async () => {
    axios.get(accountList).then((res) => {
      setAccountDt((dt) => ({
        ...dt,
        account: res.data.respBody,
      }));
    });
    const res_1 = await axios.get(`${walletDetails}`);
    const { respBody, respCode } = res_1.data;
    if (res_1.status == 200 && respCode == 0) {
      setFormDt((dt) => ({
        ...dt,
        settlementTime: respBody.settlementTime,
      }));
    }
  };
  const saveChanges = (url) => {
    setLoading(true);
    axios
      .put(url)
      .then((res) => {
        toast.success(res.data.respDesc, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const { bankDetailId, primaryAcc, settlementType, settlementTime } = formDt;
  const handleClick = () => {
    if (bankDetailId) {
      switch (settlementType) {
        case 'INSTANT':
          {
            const { primaryAcc, settlementTime, ...data } = formDt || {};
            const url = new URLSearchParams(data);
            saveChanges(`${personalUpdate}?${url.toString()}`);
          }
          break;
        case 'TPLUS1':
          {
            if (settlementTime) {
              const { primaryAcc, ...data } = formDt || {};
              const url = new URLSearchParams(data);
              saveChanges(`${personalUpdate}?${url.toString()}`);
            } else {
              toast.error('You need to choose a settlement schedule', {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
            }
          }

          break;
        default:
          break;
      }
    } else {
      toast.error('You need to choose a bank', {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getAccountList();
    if (!isEmpty(accountDt?.account)) {
      const defaultAcc = accountDt?.account.filter(
        (acc) => acc?.makePrimary === true
      );
      setFormDt((s) => ({
        ...s,
        primaryAcc: defaultAcc[0],
        bankDetailId: defaultAcc[0]?.id,
        settlementType: `${
          defaultAcc[0]?.bankName !== 'WAYA MICROFINANCE BANK'
            ? 'TPLUS1'
            : 'INSTANT'
        }`,
      }));
    }
  }, [accountDt?.account?.length]);
  console.log(formDt, 'formDt');
  return (
    <div>
      <Box
        sx={{ justifyContent: 'space-between', display: 'flex', mb: 2, pb: 2 }}
      >
        <Typography variant={'subtitle1'}>{'Preferences'}</Typography>
        <button className="button" onClick={handleClick}>
          {'Save Changes'}
          {loading && (
            <Spinner as="span" animation="border" size="sm" role="status" />
          )}
        </button>
      </Box>

      <Divider />
      <Box sx={{ width: '100%' }}>
        <Grid
          container
          className="container__item"
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={6}>
            <FormLabel id="demo-radio-buttons-group-label">
              {'Settlement Type'}
            </FormLabel>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={settlementType}
                name="settlementType"
              >
                <FormControlLabel
                  value="INSTANT"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#FF6700',
                        },
                      }}
                    />
                  }
                  label="Instant"
                  onChange={handleChange}
                />
                {settlementType === 'INSTANT' && (
                  <select name={'bankDetailId'} onChange={handleChange}>
                    <option value={''}>{'Select a bank'}</option>
                    {accountDt.account?.map(({ bankName, id }) => {
                      if (bankName === 'WAYA MICROFINANCE BANK')
                        return (
                          <option value={id} selected={primaryAcc?.id === id}>
                            {bankName}
                          </option>
                        );
                    })}
                  </select>
                )}

                <FormControlLabel
                  value="TPLUS1"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': {
                          color: '#FF6700',
                        },
                      }}
                    />
                  }
                  label="Others"
                  onChange={handleChange}
                />
                {settlementType === 'TPLUS1' && (
                  <select
                    onChange={handleChange}
                    name={'bankDetailId'}
                    defaultValue={primaryAcc?.id}
                  >
                    <option value="">{'Select bank'}</option>
                    {accountDt.account?.map(({ bankName, id }) => {
                      if (bankName !== 'WAYA MICROFINANCE BANK')
                        return <option value={id}>{bankName}</option>;
                    })}
                  </select>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Divider />
      </Box>
      {settlementType === 'TPLUS1' && (
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            className="container__item"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <label>{'Settlement Schedule'}</label>
            </Grid>
            <Grid item xs={6}>
              <select
                name={'settlementTime'}
                onChange={handleChange}
                value={settlementTime}
              >
                <option>{'Select when to be settled'}</option>
                <option value={'DAILY'}>{'DAILY (T+1)'}</option>
                <option value={'WEEKLY'}>{'WEEKLY'}</option>
              </select>
            </Grid>
          </Grid>
          {/*     <Grid
            container
            className="container__item"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <label>{"Preferred Earning Method"}</label>
            </Grid>

            <Grid item xs={6}>
              <FormGroup>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={"waya"}
                      name={"whichAccount"}
                      checked={whichAccount === "waya"}
                    />
                  }
                  label="Settle to my Wayabank Account "
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleChange}
                      value={"TPLUS1"}
                      name={"whichAccount"}
                      checked={whichAccount === "TPLUS1"}
                    />
                  }
                  label="Settle to my bank account"
                />
                {whichAccount === "TPLUS1" && (
                  <select onChange={handleChange} name={"bankDetailId"}>
                    <option>{"Select bank"}</option>
                    {accountDt.account?.map(({ bankName, id }) => {
                      return <option value={id}>{bankName}</option>;
                    })}
                  </select>
                )}
              </FormGroup>
            </Grid>

            <Grid item xs={6}>
              <label>{"Settlement Schedule"}</label>
            </Grid>
            <Grid item xs={6}>
              <div>
                <select name={"settlementTime"} onChange={handleChange}>
                  <option>{"Select when to be settled"}</option>
                  <option value={"DAILY"}>{"DAILY"}</option>
                  <option value={"WEEKLY"}>{"WEEKLY"}</option>
                </select>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Place settlement on hold until"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <label>{"Settlement Type"}</label>
            </Grid>
            <Grid item xs={6}>
              <div>
                <select name={"settlementType"} onChange={handleChange}>
                  <option>{"Select type of settlement"}</option>
                  <option value={"INSTANT"}>{"INSTANT"}</option>
                  <option value={"TPLUS1"}>{"TPLUS1"}</option>
                </select>
              </div>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            className="container__item"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <label>{"Send notification emails to other users"}</label>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="Send to the business email address only"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Send to all team member"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Send to specific users only"
                />
              </FormGroup>
            </Grid>
          </Grid> */}
        </Box>
      )}
    </div>
  );
};
