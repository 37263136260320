import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router';
import { Button, Card, Input, Switch } from 'antd';
import swal from 'sweetalert';
import Box from '@mui/material/Box';
import CopyToClipboard from 'react-copy-to-clipboard';
import Layout from '../../shared/Layout';
import Back from '../../../assets/image/back.svg';
import { customBaseUrl, httpGet, httpPost } from '../../../action/http';
import { ProfileContext } from '../../../store/context/ProfileContext';

export default function ApiDocumentation() {
  const [secCopied, setSecCopied] = useState(false);
  const { userDetails } = useContext(ProfileContext);
  const [copied, setCopied] = useState(false);
  const [liveMode, setLiveMode] = useState(true);
  const [secreteKey, setSecreteKey] = useState([]);
  // const [setPublicKey] = useState([]);
  const [tinStatus, setTinStatus] = useState();
  const [cac, setCac] = useState();
  const [mermatStatus, setMermatStatus] = useState();
  const navigate = useNavigate();

  const getAnyDoc = async () => {
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetch-status/${userDetails.userId}`
    );
    if (res.status) {
      setMermatStatus(res?.data?.MERMAT?.adminStatus);
      console.log('mermat', mermatStatus);
      setTinStatus(res?.data?.TIN.adminStatus);
      console.log('TinStatus', tinStatus);
      setCac(res?.data?.userCAC?.adminStatus);
      console.log('CAC', cac);
    }
  };

  const handleChecked = (checked) => {
    // if (
    //   mermatStatus === 'APPROVED' &&
    //   tinStatus === 'APPROVED' &&
    //   cac === 'APPROVED'
    // ) {
    setLiveMode(!checked);
    // } else {
    //   swal('Oops', 'complete your KYC', 'error');
    // }
  };
  const getApiKeys = async () => {
    const res = await httpGet(
      `${customBaseUrl.identitymanagerservice}/api/v1/waya-merchant/fetch/api-keys`
    );
    setSecreteKey(res.data);
    // setPublicKey(res.data.activePublicKey);
  };
  const handleGenerateKeeys = async () => {
    const res = await httpPost(
      `/api/v1/waya-merchant/api-keys/generate`,
      {},
      customBaseUrl.identitymanagerservice
    );
    if (res.status) {
      console.log('generate', res);
    }
  };
  const handleInitiateMerchant = async () => {
    const userId = localStorage.getItem('userId');
    const res = await httpPost(
      `/api/v1/waya-merchant/init/${userId}`,
      {},
      customBaseUrl.identitymanagerservice
    );
    if (res && !secreteKey?.activePublicKey) {
      getApiKeys();
    }
  };

  useEffect(() => {
    handleInitiateMerchant();
    getApiKeys();
    getAnyDoc();
  }, []);

  return (
    <Layout>
      <div className="w-100">
        <div className="d-flex w-100">
          <div
            onClick={() => {
              navigate('/settings');
            }}
          >
            <img src={Back} alt="" /> Back
          </div>
          <div className="mx-5">
            <Switch
              checkedChildren="Show API Keys"
              unCheckedChildren="Hide API Keys"
              checked={!liveMode}
              onChange={handleChecked}
              defaultChecked
            />
          </div>
        </div>
        <Card className="w-100" title="API Keys & Webhooks">
          <div className="w-100 d-flex justify-content-between">
            <div>
              <h6>
                API Configuration-
                {/* {liveMode === false ? 'Text Mode' : 'Live Mode'} */}
              </h6>
            </div>
            <Button type="primary" danger>
              Save Changes
            </Button>
          </div>
          <div>
            <div className="d-flex justify-content-center my-4">
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <h6>API Test Secret Key</h6>
              </Box>
              <div className="d-flex mx-3">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 500,
                    height: 40,
                    backgroundColor: '#c4c4c4',
                    borderRadius: '5PX',
                  }}
                >
                  {/* <p className="mx-1">
                    {liveMode === true ? secreteKey.activeSecretKey : ''}
                  </p> */}
                  <p className="mx-1">
                    {!liveMode === false
                      ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                      : secreteKey?.activeSecretKey}
                  </p>
                  <CopyToClipboard
                    text={secreteKey?.activeSecretKey}
                    onCopy={() => {
                      setSecCopied(true);
                      setTimeout(() => {
                        setSecCopied(false);
                      }, 1000);
                    }}
                  >
                    <div className="d-flex mx-2 my-1">
                      <Button
                        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                      >
                        {secCopied === false ? 'Copy key' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </Box>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <h6>API Test Public Key</h6>
              </Box>
              <div className="d-flex mx-2 bg-light text-dark">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: 500,
                    height: 40,
                    backgroundColor: '#c4c4c4',
                    borderRadius: '5PX',
                  }}
                >
                  {/* <p className="mx-1">
                    {liveMode === true ? secreteKey.activePublicKey : ''}
                  </p> */}
                  <p className="mx-1">
                    {!liveMode === false
                      ? 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                      : secreteKey?.activePublicKey}
                  </p>
                  <CopyToClipboard
                    text={secreteKey?.activePublicKey}
                    onCopy={() => {
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                  >
                    <div className="d-flex mx-2 my-1">
                      <Button
                        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                      >
                        {copied === false ? 'Copy key' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </Box>
              </div>
            </div>
            <div className="d-flex justify-content-center my-3">
              <Button onClick={handleGenerateKeeys}>
                Generate New Secrete Key
              </Button>
            </div>
            <div>
              <div className="d-flex justify-content-center w-50">
                <h6>Text Callback URL</h6>
                <Input size="large" placeholder="Enter Text Callback URL" />
              </div>
              <div className="d-flex justify-content-center w-50 my-4">
                <h6>Text Webhook URL</h6>
                <Input size="large" placeholder="Enter Text Webhook URL" />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
}
