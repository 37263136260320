import { IFrame } from '../../../../../components/DashboardComponent/IFrame';
import { NewTopUp } from '../../../../../components/DashboardComponent/NewTopUp';
import Modal from '../../../../../components/Modal';

export const TopUpModal = ({ setShowTopUp, showTopUp }) => {
  const { frame, show } = showTopUp;
  return (
    <Modal
      show={show}
      onclick={() =>
        setShowTopUp((s) => ({ ...s, show: false, frame: 'topUp' }))
      }
      title={`Topup your wallet`}
      clsx={{ width: frame === 'wayaQuick' ? '75%' : '' }}
    >
      <NewTopUp setShowTopUp={setShowTopUp} />
    </Modal>
  );
};
