import React from 'react';
import './select.scss';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';
import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { Col, Row } from 'react-bootstrap';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import IconButton from '@material-ui/core/IconButton';

const SlideContent = (props) => {
  return (
    <div className="slide-item pointer" key={props.content.id}>
      {props.content?.num > 0 && (
        <Col className="fs-2 d-flex justify-content-end text-white">
          <IoCheckmarkCircleOutline />
        </Col>
      )}
      <Row>
        <Col className="product-bg d-flex justify-content-center">
          <img src={props.img} alt="" />
        </Col>
        <Col className="mt-35">
          <h4 className="text-white fs-25 m-0">{props.content.terminalName}</h4>
          <h4 className="text-semi-white fs-14 fw-400">
            {props.content.terminalCategory}
          </h4>
          <div className="d-flex justify-content-around mt-20">
            {props.content?.num > 0 && (
              <IconButton
                className="action-bg text-center"
                onClick={() => props.subtractQuantity(props.content)}
              >
                <Minus />
              </IconButton>
            )}

            {props.content?.num > 0 && (
              <h4 className="text-white fs-14 mt-02">{props.content.num}</h4>
            )}

            <IconButton
              className="action-bg text-center"
              onClick={() => props.addQuantity(props.content)}
            >
              <Plus />
            </IconButton>
          </div>
          <h4 className="text-white fs-20 mt-20">
            ₦ {parseInt(props.content.amount, 10)?.toLocaleString() || 0}
          </h4>
        </Col>
      </Row>
    </div>
  );
};

export default SlideContent;
