import axios from "axios";

let authToken = null;
// if (typeof window !== "undefined") {
if (localStorage?.getItem("userDetails")) {
  // console.log(JSON.parse(localStorage.getItem('userDetails')))
  try {
    const token = localStorage.getItem("token") || {};
    authToken = token;
  } catch {
    authToken = null;
  }
}
// }
authToken = localStorage.getItem("token") || {};
const instance = axios;

if (authToken) {
  instance.defaults.headers.common["Authorization"] = `${authToken}`;
  // instance.defaults.headers.common['Client-Id'] = 'WAYABANK';
  // instance.defaults.headers.common['Client-Type'] = 'CORPORATE';
}
//  axios.interceptors.request.use((config)=> {
//       console.log({ config: config.headers });
//     })
instance.interceptors.request.use((co) => {
  if(co.headers['CLIENT-ID'] !== 'WAYABANK') {
    instance.defaults.headers.common['CLIENT-ID'] = 'WAYAPOS';
    instance.defaults.headers.common['CLIENT-TYPE'] = 'CORPORATE';
    instance.defaults.headers.common["Authorization"] = `${authToken}`;
  }
  return co;
})
// instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
instance.defaults.headers.common["accept"] =
  "application/x-www-form-urlencoded";
  // instance.defaults.headers.common['Client-Id'] = 'WAYABANK';
  // instance.defaults.headers.common['Client-Type'] = 'CORPORATE';
  // instance.defaults.headers.common['accept']  = 'application/json';

export default instance;
