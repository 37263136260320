import * as React from 'react';
//  import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const Label = styled('label')({
  display: 'block',
});

const Input = styled('input')(({ theme }) => ({
  width: 200,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.getContrastText(theme.palette.background.paper),
}));

const Listbox = styled('ul')(({ theme }) => ({
  width: 200,
  margin: 0,
  padding: 0,
  zIndex: 1,
  position: 'absolute',
  listStyle: 'none',
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
  maxHeight: 200,
  border: '1px solid rgba(0,0,0,.25)',
  '& li[data-focus="true"]': {
    backgroundColor: '#4a8df6',
    color: 'white',
    cursor: 'pointer',
  },
  '& li:active': {
    backgroundColor: '#2977f5',
    color: 'white',
  },
}));
StyledAutoComplete.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  display: PropTypes.string,
  value: PropTypes.object,
  handleSelect: PropTypes.func,
  width: PropTypes.number,
};
export default function StyledAutoComplete({
  options,
  label,
  display,
  value,
  handleSelect,
  width,
}) {
  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth
      sx={{
        width,
        '&.css-1npsg1g-MuiAutocomplete-root.MuiOutlinedInput-root': {
          padding: 0,
        },
        border: 'none',
      }}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option[label]}
      value={value || {}}
      onChange={(event, newValue) => {
        handleSelect(newValue);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            '& > img': { mr: 2, flexShrink: 0 },
            '&.css-1npsg1g-MuiAutocomplete-root.MuiOutlinedInput-root': {
              padding: 0,
            },
          }}
          {...props}
        >
          {option[display]}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          sx={{ '& .css-hdw1oc': { display: 'none' } }}
          {...params}
          fullWidth
          inputProps={{
            style: { padding: 0 },
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
