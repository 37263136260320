import React from 'react';
import './comingSoon.scss';
import CS from './comingSoon.png';
import Layout from '../../shared/Layout';

const ComingSoon = (props) => {
  return (
    <Layout route={props} currentPage="commingSoon" pageName="" showName>
      <div className="cs-wrapper">
        <div className="content">
          <img src={CS} alt="coming-soon" />
          <div className="cs-text">
            <div className="cst-1">COMING SOON</div>
            <div className="cst-2">This feature is not available yet.</div>
            <div className="cst-3">STAY TUNED</div>
            <div className="cst-4" onClick={() => props.navigate('/wallet')}>
              Go back to Home
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ComingSoon;
