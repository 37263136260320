import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal, ModalHeader } from 'reactstrap';
import Button from '../../../shared/Button';
import OtherDropdown from '../../../shared/Dropdown/OtherDropdown';
import avatar2 from '../../../../assets/image/avatar2.svg';
import moreVertical from '../../../../assets/image/moreVertical.svg';

const PageFollowers = (props) => {
  const {
    showModal,
    hideModal,
    members,
    btnLoading,
    profileData,
    selected,
    setSelected,
    setShowReportModal,
    setReportedProfile,
  } = props;
  const navigate = useNavigate();
  const [showUDropdown, setShowUDropdown] = useState(false);
  console.log('members>>', members);

  const membersAdminDDList = [
    { name: 'Make Group Admin', type: 'Make Group Admin' },
    { name: 'Report', type: 'Report' },
  ];

  const handleOtherOptions = (type) => {
    // if (type === 'Make Group Admin') {

    if (type === 'Report') {
      setShowReportModal(true);
    }
  };

  const membersDDList = [{ name: 'Report', type: 'Report' }];

  return (
    <Modal
      isOpen={showModal}
      centered
      toggle={() => hideModal(false)}
      size="lg"
    >
      <ModalHeader>
        Followers {`(${profileData?.numberOfFollowers})`}
      </ModalHeader>
      <div className="request-cont">
        {members?.length ? (
          members.map((member) => (
            <div className="request" key={member?.id}>
              <div
                className="req-img"
                onClick={() => {
                  navigate(`/wayagram-profile/${member?.Profile?.id}`);
                }}
              >
                <img src={member?.Profile?.avatar || avatar2} alt="" />
              </div>
              <div className="req-det">
                <div
                  className="det-det"
                  onClick={() => {
                    navigate(`/wayagram-profile/${member?.Profile?.id}`);
                  }}
                >
                  <div>{member?.Profile?.displayName}</div>
                  <div>{`@${member?.Profile?.username}`}</div>
                </div>
                <div
                  className="det-button"
                  style={{
                    position: 'relative',
                  }}
                >
                  {profileData?.userId !== member?.userId && (
                    <Button
                      type="button"
                      className={
                        member?.Profile?.connection?.connected === false
                          ? 'btn-follow'
                          : 'btn-following'
                      }
                      disabled={member?.Profile?.connection?.connected}
                      // onClick={() => {
                      //   handleFollowUser(
                      //     member?.userId,
                      //     member?.Profile?.username
                      //   );
                      //   setSelected(member?.id);
                      // }}
                      loading={btnLoading && member?.id === selected}
                      content={
                        member?.Profile?.connection?.connected === false
                          ? 'follow'
                          : 'following'
                      }
                    />
                  )}

                  <div
                    className="add-cursor rec"
                    onClick={() => {
                      setReportedProfile(member);
                      setSelected(member?.id);
                      setShowUDropdown(!showUDropdown);
                    }}
                  >
                    <img src={moreVertical} alt="moreVertical" />
                  </div>
                  {showUDropdown && member?.id === selected && (
                    <OtherDropdown
                      hide={setShowUDropdown}
                      options={
                        profileData.isAdmin ? membersAdminDDList : membersDDList
                      }
                      positionLeft="-127px"
                      style={{
                        top: '52px',
                      }}
                      data={member?.Profile}
                      handleClick={handleOtherOptions}
                    />
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="wl-100 my-4 text-center">No Follwers</div>
        )}
      </div>
      ;
    </Modal>
  );
};

export default PageFollowers;
