import { useState } from "react";
import { Container } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import useSimpleTable from "../../components/Hooks/useSimpleTable";
import Layout from '../../../components/shared/Layout';
import { activityFilter, viewActivity } from "../../plugins/urls";
import {
  activityLogFilters,
  activityLogTableCols,
} from "../../utilities/dummyData";

import Box from "@material-ui/core/Box";
import moment from "moment";
import useUser from "../../components/Hooks/useUser";
import CircularLoader from "../../components/SubmitLoader/CircularLoader";

const Activity = () => {
  const { user } = useUser();

  const [activity, setActivity] = useState({});
  const [loading, setLoading] = useState({});

  const tableAction = (act) => setActivity(act);

  /* custom hooks */
  const { simpleTable, searchPane } = useSimpleTable({
    setLoading,
    url: viewActivity,
    includeMeta: true,
    filters: activityLogFilters,
    filterUrl: activityFilter,
    method: "get",
    parseString: true,
    from: "2022-01-01",
    to: moment().format("YYYY-MM-DD"),
    showSearchIcon: true,
    csvName: "WAYA_POS_TERMINALS_",
    dateFilterMenuName: "duration",
    cols: activityLogTableCols(
      tableAction,
      <AiFillEye size={20} color="#FF6700" />
    ),
    filterCallMethod: 3,
    dataAddOn: {
      userID: user?.id,
    },
  });

  return (
    <Layout title="Activity Log">
      <Container
        fluid
        className="p-0"
        style={{ maxHeight: 500, overflow: "auto" }}
      >
        <Box className="col-lg-6">{searchPane}</Box>
        <Box>{loading ? <CircularLoader /> : simpleTable}</Box>
      </Container>
      {/* 
			<Container fluid className='h-100'>
				<div className='about-activity mt-4'>
					<h4 className='fs-14 fw-bold mb-2 text-capitalize'>About this activity</h4>
					<div
						className='about-activity-hr h-25'
						style={{ width: '11%', borderBottom: '4px solid #E7472C'}}>
						<ActivityDetails activity={activity} />
					</div>
				</div>
			</Container> */}
    </Layout>
  );
};

export default Activity;
