import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import swal from 'sweetalert';
import Layout from '../../shared/Layout';
import bettingIcon from '../../../assets/image/Betting Icon.svg';
import utilityIcon from '../../../assets/image/Utility Icon.svg';
import cableIcon from '../../../assets/image/Cable Icon.svg';
import airTimeIcon from '../../../assets/image/Airtime Icon.svg';
import dataIcon from '../../../assets/image/Data Icon.svg';
import otherIcon from '../../../assets/image/othersPM.svg';
import RightSidebarWrapper from './style';
import TopTabs from './TopTabs';
import './test.scss';
import MainContent from './MainContent';
import FundWallet from '../../shared/Modals/FundWallet';
import PinModal from '../../shared/Modals/PinModal';
import WithdrawModal from '../../shared/Modals/Withdraw';
import RequestModal from '../../shared/Modals/Request';
import TransferModal from '../../shared/Modals/Transfer';
import TransferModalOptions from '../../shared/Modals/Transfer/OptionModal';
import ReceiveModal from '../../shared/Modals/Receive';
import AirtimeModal from '../../shared/Modals/Billers/Airtime';
import BettingModal from '../../shared/Modals/Billers/Betting';
import CableModal from '../../shared/Modals/Billers/Cable';
import DataModal from '../../shared/Modals/Billers/Data';
import UtilityBillsModal from '../../shared/Modals/Billers/UtilityBills';
import QrCodeModal from '../../shared/Modals/QrCode';
import Profile from '../../shared/Modals/Profile';
// import { WayaBankIcon } from '../../shared/Icons';
import WayaBankIcon from '../../../assets/newlog.svg';
import { PaymentContext } from '../../../store/context/PaymentContext';
import { ProfileContext } from '../../../store/context/ProfileContext';
import {
  WalletContext,
  WalletContextProvider,
} from '../../../store/context/WalletContext';
import { ModalContext } from '../../../store/context/ModalContext';
import TopNotification from './TopNotification';
//  import { getIsBvnLinked } from '../../../store/calls';
import PayToWallet from '../../shared/Modals/Transfer/PayToWallet';
import DownloadApp from './DownloadApp';
import { WayagramContext } from '../../../store/context/WayagramContext';
import CommingSoonModal from '../../shared/Modals/comingSoon/CommingSoonModal';
import AllBillsPayment from './AllCategories';
import { BillerContext } from '../../../store/context/BillerContext';
import { customBaseUrl, httpGet } from '../../../action/http';
import EPin from '../../shared/Modals/Billers/Epin';

export default function Wallet(props) {
  // const { history } = props;
  const [currentTab, setCurrentTab] = useState('wayapay');
  const [otp, setOtp] = useState('');
  const [data, setData] = useState({});
  const [showFund, setShowFund] = useState(false);
  const [showPin, setShowPin] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showTransferModalOptions, setShowTransferModalOptions] =
    useState(false);
  const [showPayToWallet, setShowPayToWallet] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showScan, setShowScan] = useState(false);
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const [showAirtimeModal, setShowAirtimeModal] = useState(false);
  const [showBettingModal, setShowBettingModal] = useState(false);
  const [showEpinModal, setShowEpinModal] = useState(false);
  const [showCableModal, setShowCableModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);
  const [showUtilityBillsModal, setShowUtilityBillsModal] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);
  const [commingSoon, setCommingSoon] = useState(false);
  const [categories, setCategories] = useState([]);
  const [airtimeId, setAirtimeId] = useState('');
  const {
    setIsBvnLinked,
    userDetails,
    reloadUser,
    emailVerified,
    phoneVerified,
    isBvnLinked,
  } = useContext(ProfileContext);
  const {
    sentRequest,
    receivedRequest,
    isTimeout,
    setIsTimeout,
    getSentPaymentRequest,
    getReceivedPaymentRequest,
    charges,
  } = useContext(PaymentContext);
  const { walletLoading, setWalletLoading, defaultWallet } =
    useContext(WalletContext);
  const {
    setShowCreatePin,
    setModalAction,
    setShowPinVerify,
    verified,
    setIsValidatePin,
    setCreateProfile,
  } = useContext(ModalContext);
  const { wayagramProfile } = useContext(WayagramContext);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');

  const handleIsBvnLinked = async () => {
    if (!isBvnLinked) {
      setShowAlert(true);
      setShowAlertType('bvn');
      setIsBvnLinked(false);
    }
  };

  const getBillsCategory = async () => {
    const res = await httpGet(
      `${customBaseUrl.billPaymentUrl2}/api/v1/fetchAllCategory`
    );
    if (res?.status) {
      console.log('billerCategory', res);
      setCategories(res.data);
      setAirtimeId(res?.data?.filter((e) => e?.name === 'airtime')[0]?.id);
    }
  };

  useEffect(() => {
    if (isTimeout) {
      if (userDetails.userId && !userDetails.pinCreated) {
        setShowCreatePin(true);
        setModalAction('createPin');
      }
      setModalAction('verifyTimeoutPin');
      setShowPinVerify(true);
      setIsValidatePin(true);
    }
    return () => {
      setModalAction('');
      setShowPinVerify(false);
    };
  }, [isTimeout]);

  useEffect(() => {
    if (verified) setIsTimeout(false);
  }, [verified]);

  useEffect(() => {
    const firstLogin = localStorage.getItem('firstLogin');
    // const editPrompt = localStorage.getItem('editPrompt');
    if (userDetails.userId && !userDetails.pinCreated) {
      setShowCreatePin(true);
      setModalAction('createPin');
      // console.log('userDetails.userId => userDetails.userId', userDetails);
    }

    // if (userDetails.userId && firstLogin === 'true') {
    //   localStorage.setItem('firstLogin', 'false');
    //   setCreateProfile(true);
    // }
    if (reloadUser) {
      // const check = false;
      // if (check) setShowPin(true);
      if (firstLogin === 'true' && reloadUser) {
        setShowProfile(true);
        localStorage.setItem('firstLogin', false);
      }
    }
  }, [userDetails?.userId, reloadUser, wayagramProfile]);

  useEffect(() => {
    setTimeout(() => {
      setWalletLoading(false);
      localStorage.setItem('showSplashScreen', false);
    }, 5000);

    if (!emailVerified) {
      setShowAlertType('verifyEmail');
      setShowAlert(true);
    }
  }, []);

  useEffect(() => {
    if (emailVerified && phoneVerified) {
      setShowAlert(false);
      handleIsBvnLinked();
    }
    if (!phoneVerified) {
      setShowAlertType('verifyPhone');
      setShowAlert(true);
    }
    // console.log(emailVerified);
    if (!emailVerified) {
      setShowAlertType('verifyEmail');
      setShowAlert(true);
    }
  }, [emailVerified, phoneVerified]);
  useEffect(() => {
    getBillsCategory();
  }, []);
  const showSplashScreen = localStorage.getItem('showSplashScreen');
  if (showSplashScreen === 'true' && walletLoading === true) {
    return (
      <div
        className="row d-grid justify-content-center align-items-center splash-bg"
        style={{ height: '100vh' }}
      >
        <img src={WayaBankIcon} width="150px" alt="icon" />
      </div>
    );
  }

  console.log('&'.repeat(20));

  return (
    <Layout
      routeHistory={props}
      currentPage="products"
      pageName="Wallet"
      defaultWallet={defaultWallet}
      showName
      getD={defaultWallet}
    >
      <div className="row wayapay-div">
        <div className="col-md-8">
          <TopTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setShowWithdraw={setShowWithdraw}
            setShowRequest={setShowRequestModal}
            setShowTransfer={setShowTransferModalOptions}
            setShowReceive={setShowReceiveModal}
            setCommingSoon={setCommingSoon}
          />
          <TopNotification showAlert={showAlert} type={showAlertType} />
          <MainContent
            setShowFund={setShowFund}
            setShowQrCodeModal={setShowQrCodeModal}
            showAlert={showAlert}
            setShowAirtimeModal={setShowAirtimeModal}
            setShowDataModal={setShowDataModal}
            setShowUtilityBillsModal={setShowUtilityBillsModal}
            setShowCableModal={setShowCableModal}
          />
          <Routes>
            <Route
              exact
              path="/wallet/all_billers"
              component={() => <AllBillsPayment />}
            />
          </Routes>
        </div>
        <RightSidebarWrapper className="col-md-4">
          <div className="items-right-list">
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowUtilityBillsModal(true)}
            >
              <img src={utilityIcon} alt="" />
              <span>Utility Bills</span>
            </div>
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowCableModal(true)}
            >
              <img src={cableIcon} alt="" />
              <span>Cable</span>
            </div>
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowAirtimeModal(true)}
            >
              <img src={airTimeIcon} alt="" />
              <span>Airtime</span>
            </div>
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowDataModal(true)}
            >
              <img src={dataIcon} alt="" />
              <span>Data</span>
            </div>
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowBettingModal(true)}
            >
              <img src={bettingIcon} alt="" />
              <span>Betting</span>
            </div>
            <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => setShowEpinModal(true)}
            >
              <img src={otherIcon} alt="" />
              <span>Epin</span>
            </div>
            {/* <div
              className="items-right-list-item"
              role="button"
              tabIndex={0}
              aria-hidden="true"
              onClick={() => navigate('/wallet/all_billers')}
            >
              <img src={otherIcon} alt="" />
              <span>Other Bills Payment</span>
            </div> */}
          </div>
          <DownloadApp styles="bottom-item-box top-350" />
        </RightSidebarWrapper>
      </div>
      {showFund ? (
        <FundWallet
          showModal={showFund}
          hideModal={setShowFund}
          setShowPin={setShowPin}
          id="fund-wrapper"
        />
      ) : (
        ''
      )}
      {showPin ? (
        <PinModal
          center
          showModal={showPin}
          hideModal={setShowPin}
          otp={otp}
          setOtp={setOtp}
          separator=""
          buttonLabel={showFund ? 'Top-up Wallet' : 'Send'}
          title="Input your 4 digit PIN"
          description="Please input your 4 digit PIN to confirm transaction"
        />
      ) : (
        ''
      )}
      {showProfile ? (
        <Profile
          center="true"
          hideModal={setShowProfile}
          showModal={showProfile}
        />
      ) : (
        ''
      )}
      {showWithdraw ? (
        <WithdrawModal
          showModal={showWithdraw}
          hideModal={setShowWithdraw}
          setShowPin={setShowPin}
          id="withdraw-wrapper"
        />
      ) : (
        ''
      )}
      <CommingSoonModal showModal={commingSoon} hideModal={setCommingSoon} />
      {showRequestModal ? (
        <RequestModal
          showModal={showRequestModal}
          hideModal={setShowRequestModal}
          setShowPin={setShowPin}
          sentRequest={sentRequest}
          receivedRequest={receivedRequest}
          getSentPaymentRequest={getSentPaymentRequest}
          getReceivedPaymentRequest={getReceivedPaymentRequest}
          id="request-wrapper"
        />
      ) : (
        ''
      )}
      {showTransferModal ? (
        <TransferModal
          showModal={showTransferModal}
          hideModal={setShowTransferModal}
          setShowPin={setShowPin}
          id="success-wrapper"
          charges={charges.walletTransferFee}
        />
      ) : (
        ''
      )}
      {showScan ? (
        <TransferModal
          showModal={showScan}
          hideModal={setShowScan}
          setShowPin={setShowPin}
          scan
          id="success-wrapper"
          charges={charges.walletTransferFee}
        />
      ) : (
        ''
      )}
      {showTransferModalOptions ? (
        <TransferModalOptions
          showModal={showTransferModalOptions}
          hideModal={setShowTransferModalOptions}
          setShowOuter={setShowTransferModal}
          setShowInner={setShowPayToWallet}
          setShowWithdraw={setShowWithdraw}
          setShowScan={setShowScan}
          id="transfer-options"
        />
      ) : (
        ''
      )}
      {showPayToWallet ? (
        <PayToWallet
          showModal={showPayToWallet}
          hideModal={setShowPayToWallet}
          id="success-wrapper"
          charges={charges.billPaymentFee}
        />
      ) : (
        ''
      )}
      {showReceiveModal ? (
        <ReceiveModal
          showModal={showReceiveModal}
          hideModal={setShowReceiveModal}
          setShowPin={setShowPin}
          id="receive-wrapper"
        />
      ) : (
        ''
      )}
      {showAirtimeModal ? (
        <AirtimeModal
          showModal={showAirtimeModal}
          hideModal={setShowAirtimeModal}
          setShowPin={setShowPin}
          walletdata={data}
          setWalletData={setData}
          id="billpayment-wrapper"
          categories={categories}
          airtimeId={airtimeId}
        />
      ) : (
        ''
      )}
      {showBettingModal ? (
        <BettingModal
          showModal={showBettingModal}
          hideModal={setShowBettingModal}
          walletdata={data}
          setWalletData={setData}
          setShowPin={setShowPin}
          id="billpayment-wrapper"
        />
      ) : (
        ''
      )}
      {showCableModal ? (
        <CableModal
          showModal={showCableModal}
          hideModal={setShowCableModal}
          setShowPin={setShowPin}
          walletdata={data}
          setWalletData={setData}
          id="billpayment-wrapper"
        />
      ) : (
        ''
      )}
      {showEpinModal ? (
        <EPin
          showModal={showEpinModal}
          hideModal={setShowEpinModal}
          setShowPin={setShowPin}
          walletdata={data}
          setWalletData={setData}
          id="billpayment-wrapper"
        />
      ) : (
        ''
      )}
      {showDataModal ? (
        <DataModal
          showModal={showDataModal}
          hideModal={setShowDataModal}
          setShowPin={setShowPin}
          walletdata={data}
          setWalletData={setData}
          id="billpayment-wrapper"
        />
      ) : (
        ''
      )}
      {showUtilityBillsModal ? (
        <UtilityBillsModal
          showModal={showUtilityBillsModal}
          hideModal={setShowUtilityBillsModal}
          setShowPin={setShowPin}
          walletdata={data}
          setWalletData={setData}
          id="billpayment-wrapper"
        />
      ) : (
        ''
      )}
      {showQrCodeModal ? (
        <QrCodeModal
          hideModal={setShowQrCodeModal}
          showModal={showQrCodeModal}
          mode="profile"
        />
      ) : (
        ''
      )}
    </Layout>
  );
}
