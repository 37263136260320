import React, { useState } from 'react';
import './SideBar.scss';
import { ReactComponent as SideActivities } from '../../../assets/icons/side_activities.svg';
import { ReactComponent as SideDespute } from '../../../assets/icons/side_despute.svg';
import { ReactComponent as SideOverview } from '../../../assets/icons/side_overview.svg';
import { ReactComponent as SideSettings } from '../../../assets/icons/side_settings.svg';
import { ReactComponent as SideSettlements } from '../../../assets/icons/side_settlements.svg';
import { ReactComponent as SideStarted } from '../../../assets/icons/side_started.svg';
import { ReactComponent as SideSubmit } from '../../../assets/icons/side_submit.svg';
import { ReactComponent as SideTerminals } from '../../../assets/icons/side_terminals.svg';
import { ReactComponent as SideTransactions } from '../../../assets/icons/side_transactions.svg';
import { ReactComponent as SideWayaBank } from '../../../assets/icons/side_wayabank.svg';
import { ReactComponent as SideWayaGram } from '../../../assets/icons/side_wayagram.svg';
import { ReactComponent as SideWayaWeb } from '../../../assets/icons/side_web.svg';
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import Logo from '../../../assets/newLogo.png';
import { NavLink } from 'react-router-dom';
import Divider from '../../Divider/Divider';
import { useNavigate } from 'react-router-dom';
import { Alert, Box } from '@mui/material';
import { copyText } from '../../../utilities/stringOperations';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Col from 'react-bootstrap/Col';
import { Slide, toast } from 'react-toastify';
import useUser from '../../Hooks/useUser';
import Loader from '../../Loader';
import UpdateProfileModal from '../../../pages/Settings/pages/updateAccount/components/UpdateProfileModal';
import { getPosMerchantProfile, getUseRole } from '../../../plugins/urls';
import { useEffect } from 'react';
import axios from 'axios';

export const logout = ({ setLoading, nav }) => {
  typeof setLoading === 'function'
    ? setLoading(true)
    : toast.info(
        `You're logging out, it's good practice to close your tabs after logging off!`,
        {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
  setTimeout(async () => {
    localStorage.clear();
    await nav('/login');
  }, 3000);
};

const SideBar = (props) => {
  const userObj = useUser() || {};
  const [isCopied, setIsCopied] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState([]);
  //if (!userObj?.user?.posUserProfile?.merchantId) navigate("/login");
  const {
    user: { firstName, organisationName },
  } = userObj || {};
  /* useEffect(() => {
    getPosMerchantProfile
  }, []) */

  const merchantId = userObj?.posUserProfile?.merchantId ?? '';

  const copySuccess = ({ copied, str }) => {
    if (copied === str) {
      setIsCopied(true);
    } else {
      setIsCopied(false);
    }
    setTimeout(() => setIsCopied(null), 3000);
  };

  const getUserAssignedMenu = () => {
    axios.get(getUseRole).then((res) => {
      setMenu(res?.data?.data?.menu?.menuString);
    });
  };

  useEffect(() => {
    getUserAssignedMenu();
  }, []);

  const check = (menuString) => menu?.includes(menuString);
  return (
    <div>
      <div className="Sidebar">
        <div className="side-padding">
          <div className="text-center side-logo">
            <img src={Logo} width="150px" />
            <h4 className="fs-14 text-darker fw-700 mt-10">
              {organisationName?.toUpperCase()}
            </h4>
            <h4 className="fs-12 text-semi-dark">
              <Box
                className="pointer"
                onClick={() =>
                  copyText({
                    str: merchantId,
                    callback: copySuccess,
                  })
                }
              >
                <div className="row">
                  {isCopied !== null && (
                    <Alert severity={isCopied ? 'success' : 'error'}>
                      {isCopied ? ' Copied to clipboard' : 'Failed to copy'}
                    </Alert>
                  )}
                </div>
                <Col>
                  <div className="d-flex justify-content-center">
                    <p>Merchant ID: </p>
                    <div>
                      <small className="error">
                        <FileCopyOutlinedIcon color="error" />
                      </small>
                    </div>
                  </div>
                  <p>{merchantId ?? ''}</p>
                </Col>
              </Box>
            </h4>
          </div>
        </div>

        <div className="NavigationBox">
          <ul
            className={
              props.title === 'Get Started' ? 'Navigation-active' : 'Navigation'
            }
          >
            <li>
              <SideStarted
                className={
                  props.title === 'Get Started' ? 'activeIcon' : 'sideIcon'
                }
                size={20}
              />
              <NavLink to="/get-started">Getting Started</NavLink>
            </li>
          </ul>

          {check('DASHBOARD') && (
            <ul
              className={
                props.title === 'Overview' ? 'Navigation-active' : 'Navigation'
              }
            >
              <li>
                <SideOverview
                  className={
                    props.title === 'Overview' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/dashboard">Overview</NavLink>
              </li>
            </ul>
          )}
          {check('MANAGE KYC') && (
            <ul
              className={
                props.title === 'Kyc' ? 'Navigation-active' : 'Navigation'
              }
            >
              <li>
                <SideSubmit
                  className={props.title === 'Kyc' ? 'activeIcon' : 'sideIcon'}
                  size={20}
                />
                <NavLink to="/settings/?kyc" state={{ enableTeamMember: true }}>
                  Submit Kyc
                </NavLink>
              </li>
            </ul>
          )}

          {check('MANAGE TERMINAL') && (
            <ul
              className={
                props.title === 'Terminals' ? 'Navigation-active' : 'Navigation'
              }
            >
              <li>
                <SideTerminals
                  className={
                    props.title === 'Terminals' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/terminals">Terminals</NavLink>
              </li>
            </ul>
          )}
          {check('MANAGE TRANSACTIONS') && (
            <ul
              className={
                props.title === 'Transactions'
                  ? 'Navigation-active'
                  : 'Navigation'
              }
            >
              <li>
                <SideTransactions
                  className={
                    props.title === 'Transactions' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/transactions">Transactions</NavLink>
              </li>
            </ul>
          )}
          <ul
            className={
              props.title === 'ag' ? 'Navigation-active' : 'Navigation'
            }
          >
            <li>
              <SideWayaBank
                size={20}
                className={
                  props.title === 'Aggregator Dashboard'
                    ? 'activeIcon'
                    : 'sideIcon'
                }
              />
              <NavLink to="/aggregator-dashboard">AG Dashboard</NavLink>
            </li>
          </ul>
          {check('MANAGE SETTLEMENT') && (
            <ul
              className={
                props.title === 'Settlements'
                  ? 'Navigation-active'
                  : 'Navigation'
              }
            >
              <li>
                <SideSettlements
                  className={
                    props.title === 'Settlements' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/settlements">Settlements</NavLink>
              </li>
            </ul>
          )}
          {check('MANAGE DISPUTE') && (
            <ul
              className={
                props.title === 'Dispute' ? 'Navigation-active' : 'Navigation'
              }
            >
              <li>
                <SideDespute
                  className={
                    props.title === 'Dispute' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/disputes">Dispute</NavLink>
              </li>
            </ul>
          )}
          {check('') && (
            <ul
              className={
                props.title === 'Notifications'
                  ? 'Navigation-active'
                  : 'Navigation'
              }
            >
              <li>
                <SideActivities
                  className={
                    props.title === 'Notifications' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/notifications">Notifications</NavLink>
              </li>
            </ul>
          )}
          <ul
            className={
              props.title === 'Settings' ? 'Navigation-active' : 'Navigation'
            }
          >
            <li>
              <SideSettings
                className={
                  props.title === 'Settings' ? 'activeIcon' : 'sideIcon'
                }
                size={20}
              />
              <NavLink
                to="/settings"
                state={{ enableTeamMember: check('MANAGE ADMIN USERS') }}
              >
                Settings
              </NavLink>
            </li>
          </ul>

          {check('MANAGE AUDIT LOG') && (
            <ul
              className={
                props.title === 'Activity Log'
                  ? 'Navigation-active'
                  : 'Navigation'
              }
            >
              <li>
                <SideActivities
                  className={
                    props.title === 'Activity Log' ? 'activeIcon' : 'sideIcon'
                  }
                  size={20}
                />
                <NavLink to="/activity-log">Activity Log</NavLink>
              </li>
            </ul>
          )}
        </div>

        <Divider />

        <div className="NavigationBox">
          <ul className="Navigation">
            <li>
              <SideWayaBank className="sideIcon" size={20} />
              <a
                target="_blank"
                href="https://www.wayabank.ng"
                rel="noreferrer"
              >
                {'Wayabank'}
              </a>
            </li>
          </ul>
          <ul className="Navigation">
            <li>
              <SideWayaGram className="sideIcon" size={20} />
              <a
                target="_blank"
                href="https://app.wayagram.ng"
                rel="noreferrer"
              >
                {'Wayagram'}
              </a>
            </li>
          </ul>
          <ul className="Navigation">
            <li>
              <SideWayaWeb className="sideIcon" size={20} />
              <a
                target="_blank"
                href="https://app.wayaquick.com/overview"
                rel="noreferrer"
              >
                {'Wayaquick'}
              </a>
            </li>
          </ul>

          <ul className="Navigation">
            <li onClick={() => logout({ setLoading, nav: navigate })}>
              <Logout className="sideIcon" size={20} />
              <NavLink to="#">Log Out</NavLink>
            </li>
          </ul>
        </div>
        <UpdateProfileModal state={loading}>
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <p className="h2">
                Goodbye <span className="text-success">{firstName}</span>
              </p>
              <div>
                <Loader />
              </div>
              <p className="py-2 text-info">
                {' '}
                It's good practice to close your tabs after logging out
              </p>
            </div>
          </div>
        </UpdateProfileModal>
      </div>
    </div>
  );
};

export default SideBar;
