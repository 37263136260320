import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { AiFillEye } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import Cards from '../../components/Cards/Cards';
import FadeMenu from '../../pages/Settings/pages/updateAccount/components/FadeMenu';
import {
  allTransactions,
  revenueStats,
  searchTransactionByParam,
  transactionStats,
} from '../../plugins/urls';
import { apiCall } from '../../utilities/apis';
import {
  transactionFilters,
  transactionTableCols,
} from '../../utilities/dummyData';
import TransactionCard from '../Cards/TransactionCard';
import useSimpleTable from '../Hooks/useSimpleTable';
import EllipsisLoader from '../SubmitLoader/ElipseLoader';

const WayaPos = () => {
  const { user } = JSON.parse(localStorage.getItem('userDetails'));
  const navigate = useNavigate();
  const [state, setState] = useState({
    transactions: [],
    refunded: '',
    failed: '',
    abandoned: '',
    successful: '',
    gross_revenue: '',
    net_revenue: '',
  });
  const tableAction = (trx) => navigate(`/transaction/${trx.id}`);
  const tableAction1 = (trx) => navigate(`/transaction/${trx.id}`);

  const [menuEl, setMenuEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingStats, setLoadingStats] = useState(null);
  const [loadingRev, setLoadingRev] = useState(null);

  const { tableData, simpleTable, exportButton, searchPane } = useSimpleTable({
    setLoading,
    url: allTransactions,
    includeMeta: true,
    filters: transactionFilters,
    filterUrl: searchTransactionByParam,
    showSearchIcon: true,
    csvName: 'WAYA_POS_transactions_',
    filterMethod: 'get',
    dateFilterMenuName: 'date',
    cols: transactionTableCols(
      tableAction,
      <AiFillEye size={20} color="#FF6700" />
    ),
    startCb: () => {
      getTransactionsStats();
      getRevenueStats();
    },
  });

  const {
    failed,
    refunded,
    abandoned,
    successful,
    net_revenue,
    gross_revenue,
  } = state;

  const getTransactionsStats = () =>
    apiCall({
      url: transactionStats,
      method: 'post',
      noError: true,
      defaultMeta: true,
      setLoading: setLoadingStats,
      success: (data) => {
        if (data) {
          const { failed, refunded, abandoned, successful } = data;
          setState((state) => ({
            ...state,
            failed,
            refunded,
            abandoned,
            successful,
          }));
        }
      },
    });
  const getRevenueStats = () =>
    apiCall({
      url: revenueStats,
      method: 'get',
      noError: true,
      defaultMeta: true,
      setLoading: setLoadingRev,
      success: (data) => {
        if (data) {
          const { gross_revenue, net_revenue } = data;
          setState((state) => ({
            ...state,
            gross_revenue,
            net_revenue,
          }));
        }
      },
    });

  return (
    <>
      <FadeMenu
        temp={
          <div className="p-3">
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                Download
              </Button>
            </div>
            <div className="py-2">
              <Button variant="outlined" fullWidth>
                <a>Email</a>
              </Button>
            </div>
          </div>
        }
        open={Boolean(menuEl)}
        handleClose={() => setMenuEl(null)}
        anchorEl={menuEl}
      />

      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex flex-wrap justify-content-between filter-contents align-items-center">
          <div>{searchPane}</div>

          {tableData?.content?.length > 0 && exportButton}
        </div>
      </div>

      <Container fluid>
        {loadingStats ? (
          <EllipsisLoader />
        ) : (
          <Row className="mt-40">
            <Col xs={12} md={6} xl={4} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Gross Revenue"
                value={gross_revenue}
                color="text-orange"
                textColor="text-darker"
              />
            </Col>
            <Col xs={12} md={6} xl={4} className="my-2 mt-xl-0">
              <Cards
                cardTitle="Net Revenue"
                value={net_revenue}
                color="text-orange"
                textColor="text-darker"
              />
            </Col>
            <Col
              xs={12}
              md={6}
              xl={4}
              className="my-2 mt-xl-0 justify-content-center"
            >
              <TransactionCard
                failed={failed}
                refunded={refunded}
                abandoned={abandoned}
                successful={successful}
              />
            </Col>
          </Row>
        )}

        <div className="mt-5">
          {loading ? (
            <div className="align-items-center d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="success" />
            </div>
          ) : (
            <Container fluid>{simpleTable}</Container>
          )}
        </div>
      </Container>
    </>
  );
};

export default WayaPos;
