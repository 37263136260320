import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { NavbarMenu, NavbarWrapper } from './style';
import ManageBankAccountModal from '../Modals/BankAccount/manage';
import ManageCardModal from '../Modals/Card/manage';
import CreateWalletModal from '../Modals/Wallet';
import LinkCardModal from '../Modals/Card';
import BankAccountModal from '../Modals/BankAccount';
import ManageWallet from '../Modals/Wallet/ManageWallet';
// import accountMultiple from '../../../assets/images/accountMultiple.png';
// import imgPlaceholder from '../../../assets/image/imgPlaceholder.png';
// import { ProfileContext } from '../../../store/context/ProfileContext';
import { LayoutContext } from '../../../store/context/LayoutContext';
import WayaNavbar from './wayaNavbar';
import { ArrowBack } from '../Icons';
import WayagramNavbar from './wayagramNavbar';

export default function Navbar(props) {
  const { currentPage, pageName, goBack, showName } = props;
  const [showManageBankAccount, setShowManageBankAccount] = useState(false);
  const [showManageCard, setShowManageCard] = useState(false);
  const [showManageWallet, setShowManageWallet] = useState(false);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const [showLinkCardModal, setShowLinkCardModal] = useState(false);
  const [showAddBankAccount, setShowAddBankAccount] = useState(false);
  // const { profile: { profileImage }} = useContext(ProfileContext);
  const { setToggleMobileSidebar, toggleMobileSidebar } =
    useContext(LayoutContext);
  const navigate = useNavigate();

  return (
    <NavbarWrapper className="navbar-container">
      <NavbarMenu>
        <Button
          shape="default"
          icon={<MenuOutlined />}
          onClick={() => setToggleMobileSidebar(!toggleMobileSidebar)}
        />
      </NavbarMenu>
      {goBack && (
        <Space direction="horizontal" onClick={() => navigate('/settings')}>
          <ArrowBack />
          Back
        </Space>
      )}
      {showName && (
        <h6 className="nav-title">
          {pageName === 'Wallet' ? 'Wayabank' : pageName}
        </h6>
      )}
      {pageName !== 'Feed' ? (
        <WayaNavbar
          setShowManageBankAccount={setShowManageBankAccount}
          setShowCreateWallet={setShowCreateWallet}
          setShowManageCard={setShowManageCard}
          setShowManageWallet={setShowManageWallet}
          setToggleMobileSidebar={setToggleMobileSidebar}
          toggleMobileSidebar={toggleMobileSidebar}
          history={history}
          currentPage={currentPage}
          pageName={pageName}
        />
      ) : (
        <WayagramNavbar
          setShowManageBankAccount={setShowManageBankAccount}
          setShowCreateWallet={setShowCreateWallet}
          setShowManageCard={setShowManageCard}
          setShowManageWallet={setShowManageWallet}
          setToggleMobileSidebar={setToggleMobileSidebar}
          toggleMobileSidebar={toggleMobileSidebar}
          history={history}
          currentPage={currentPage}
          pageName={pageName}
        />
      )}
      {showManageBankAccount ? (
        <ManageBankAccountModal
          showModal={showManageBankAccount}
          hideModal={setShowManageBankAccount}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowAddBankAccount={setShowAddBankAccount}
        />
      ) : (
        ''
      )}

      {showAddBankAccount ? (
        <BankAccountModal
          showModal={showAddBankAccount}
          hideModal={setShowAddBankAccount}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}

      {showCreateWallet ? (
        <CreateWalletModal
          showModal={showCreateWallet}
          hideModal={setShowCreateWallet}
          id="account-wrapper"
        />
      ) : (
        ''
      )}

      {showManageWallet ? (
        <ManageWallet
          showModal={showManageWallet}
          hideModal={setShowManageWallet}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowCreateWallet={setShowCreateWallet}
        />
      ) : (
        ''
      )}

      {showManageCard ? (
        <ManageCardModal
          showModal={showManageCard}
          hideModal={setShowManageCard}
          id="account-wrapper"
          title="check"
          description="checking"
          center
          history={history}
          setShowLinkCardModal={setShowLinkCardModal}
        />
      ) : (
        ''
      )}

      {showLinkCardModal ? (
        <LinkCardModal
          showModal={showLinkCardModal}
          hideModal={setShowLinkCardModal}
          id="account-wrapper"
          history={history}
        />
      ) : (
        ''
      )}
    </NavbarWrapper>
  );
}

Navbar.defaultProps = {
  currentPage: 'others',
};

Navbar.propTypes = {
  currentPage: PropTypes.string,
};
