import React from 'react';
import { stringCaps } from '../utilities/stringOperations';

export const icon = ({
  icon,
  clas,
  defaultClass = 'd-flex justify-content-center p-3',
}) => (
  <div className={`${defaultClass} ${clas}`}>
    <img src={icon} alt="an icon" />
  </div>
);

export const Boxx = ({
  item,
  setCard,
  clss = 'quick-actions-card',
  iconClass = 'quick-actions-icon',
  iconDefault,
  nameClass = 'fs-18 fw-bold text-center',
  descClass,
  innerClass,
  contentClass,
  setOpen,
}) => {
  return (
    <div
      key={item?.name || 1}
      className={`pointer ${clss}`}
      onClick={() => setOpen(true)}
      /* onClick={() => setCard(item)} */
      tabIndex={item.id}
    >
      <div className={innerClass}>
        <div>
          {icon({
            icon: item.icon,
            clas: iconClass,
            defaultClass: iconDefault,
          })}
        </div>

        <div className={contentClass}>
          <div className={nameClass}>{stringCaps(item.name)}</div>
          <div className={descClass}>{item.desc}</div>
        </div>
      </div>
    </div>
  );
};
