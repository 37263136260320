import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu

const CustomToggle = React.forwardRef(
  ({ children, style, onClick, sel }, ref) => {
    return (
      <button
        style={style}
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        &#x25bc;
      </button>
    );
  }
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomMenu = React.forwardRef(
  (
    { children, style, className, setSel, 'aria-labelledby': labeledBy },
    ref
  ) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => {
            setValue(e.target.value);
            setSel(e.target.value);
          }}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const DropDownFilter = ({
  sel,
  setSel,
  optionList = [],
  keyVal = {},
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        sel={sel}
        id="dropdown-custom-components"
      >
        {sel}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} sel={sel} setSel={setSel}>
        {Array.isArray(optionList)
          ? optionList?.map((i, idx) => (
              <Dropdown.Item
                onClick={() => setSel(i[keyVal.key])}
                eventKey={i[keyVal.value]}
                value={i[keyVal.value]}
              >
                {i[keyVal.key]}
              </Dropdown.Item>
            ))
          : []}
      </Dropdown.Menu>
    </Dropdown>
  );
};
