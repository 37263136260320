import React, { useContext } from 'react';
import { SimpleTableContext } from './Table';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import SimpleDropDown from '../DropDown';

const TableFooter = () => {
  const {
    bg,
    color,
    pageNumber,
    setEntity,
    pageSize,
    totalElements,
    totalPages,
    number,
    offset,
  } = useContext(SimpleTableContext);
  const items = [5, 10, 15, 20, 50, 100];
  const setPageSize = (val) => setEntity('pageSize', val);
  const next = () => setEntity('pageNo', pageNumber + 1);
  const prev = () => setEntity('pageNo', pageNumber - 1);
  return (
    <div className={`${bg} ${color} px-5 py-2 w-100`}>
      <div className="d-flex">
        <div className="col-md-5 col-lg-6 col-xl-8 d-none d-md-block">
          &nbsp;
        </div>
        <div className="col-md-7 col-lg-6 col-xl-4 fs-12">
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              <div className="d-flex">
                <div className="pe-3 fw-bold pt-2">Rows per page:</div>
                <SimpleDropDown
                  selected={pageSize}
                  setSelected={setPageSize}
                  items={items}
                />
              </div>
            </div>

            <div className="pt-2 ">
              <span className="fw-bold">{offset - pageSize + 1}</span>
              <span className="">-</span>
              <span className="pe-1 fw-bold">{offset}</span>
              <span className="pe-1">of</span>
              <span className="pe-3 fw-bold">{totalElements}</span>
            </div>

            <div className="pt-2 ">
              {pageNumber > 1 && (
                <span onClick={prev} className="pointer">
                  {' '}
                  <span className="pe-3">
                    <FaChevronLeft />
                  </span>
                </span>
              )}
              <span className="fw-bold px-2">{pageNumber}</span>
              {pageNumber < totalPages && (
                <span onClick={next} className="pointer">
                  {' '}
                  <span className="pe-3">
                    <FaChevronRight />
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableFooter;
