import envConfig from '../../../utils/envConfig';

const logout = () => {
  localStorage.clear();
  window.location.href = envConfig.login;
  // // console.log(props);
  // props.navigate('/')
};

const Logout = () => {
  return logout();
};

export default Logout;
