import React from 'react';
import { ReactComponent as Back } from '../../assets/icons/addback.svg';
import { ReactComponent as Next } from '../../assets/icons/front.svg';
import './select.scss';

const Controls = ({ setIndex, index, itemPerPage, totalItems }) => {
  const next = () => {
    if (index < totalItems - itemPerPage) setIndex(index + 1);
  };

  const prev = () => {
    if (index > 0) setIndex(index - 1);
  };

  return (
    <div className="mt-5 flex justify-center items-center">
      <div>
        {index > 0 && (
          <button className="prev-button mr-4" onClick={prev}>
            <Back />
          </button>
        )}

        {index < totalItems - itemPerPage && (
          <button className="next-button" onClick={next}>
            <Next />
          </button>
        )}
      </div>
    </div>
  );
};

export default Controls;
