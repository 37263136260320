import axios from 'axios';
import { accountList, transferFund, walletDetails } from '../../plugins/urls';
import { useEffect } from 'react';
import { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Slide, toast } from 'react-toastify';
import './styles.scss';

export const Withdrawal = ({ onClose }) => {
  const [withdrawDt, setWithdrawDt] = useState({
    balance: 0,
    account: [],
    disabled: true,
    error: false,
  });
  const walletDetail = () => {
    axios.get(walletDetails).then((res) => {
      setWithdrawDt((dt) => ({
        ...dt,
        balance: res.data.respBody.activeBalance,
      }));
    });
  };
  const getAccountList = () => {
    axios.get(accountList).then((res) => {
      setWithdrawDt((dt) => ({
        ...dt,
        account: res.data.respBody,
      }));
    });
  };

  useEffect(() => {
    walletDetail();
    getAccountList();
  }, []);
  const { balance, account, disabled, error, amount, narration } = withdrawDt;

  const handleClose = (e) => {
    e.preventDefault();
    onClose();
  };

  const [loading, setLoading] = useState(false);
  const handleWithdraw = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${transferFund}?amount=${amount}&narration=${narration}`)
      .then((res) => {
        if (res.status === 200 && res.data.respCode === 0) {
          toast.success(
            `NGN ${amount} successfully transferred to your account`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        } else {
          toast.error(res.data.respBody, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(`An error occurred`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setWithdrawDt((dt) => ({ ...dt, [name]: value }));
    if (balance < withdrawDt.amount || withdrawDt.amount === 0) {
      setWithdrawDt((dt) => ({ ...dt, error: true }));
    } else {
      setWithdrawDt((dt) => ({
        ...dt,
        error: false,
        disabled: false,
      }));
    }
  };
  return (
    <div style={{ padding: '8px' }}>
      <form className="withdrawal-input">
        <div>
          <label>{'Balance'}</label>
          <input disabled value={`NGN ${balance}`} />
        </div>
        <div>
          <label>{'Amount to withdraw'}</label>
          <div className="amount-input">
            <span className="currency">{'NGN'}</span>
            <input
              type={'number'}
              min={0}
              name={'amount'}
              onChange={handleChange}
            />
          </div>
          {error && (
            <p className="text-red">
              {'Amount to withdraw cannot be 0 or more than balance'}
            </p>
          )}
        </div>
        <div>
          <label>{'Narration'}</label>

          <input name={'narration'} onChange={handleChange} />
        </div>
        <div>
          <label>{'Select preferred account to withdraw'}</label>
          <select>
            <option>{'Select an account'}</option>
            {account?.map(({ accountName, bankName }) => {
              return <option>{`${accountName}-${bankName}`}</option>;
            })}
          </select>
        </div>
        <div className="d-flex justify-content-end px-4 py-3">
          <button
            className="button"
            type={'button'}
            onClick={(e) => handleClose(e)}
          >
            {'Cancel'}
          </button>

          <button
            className="button"
            disabled={disabled || withdrawDt.error}
            onClick={handleWithdraw}
          >
            {'Withdraw'}
            {loading && <Spinner as="span" animation="grow" size="sm" />}
          </button>
        </div>
      </form>
    </div>
  );
};
