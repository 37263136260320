import * as React from 'react';
import format from 'date-fns/format';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  TableFooter,
} from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlined from '@mui/icons-material/ArrowForwardIosOutlined';
import PropTypes from 'prop-types';
import Image from '../../../../../components/Image';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'rgb(249 249 249)',
    color: '#202020',
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(even)': {
  //   backgroundColor: theme.palette.action.hover
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

InviteesTable.propTypes = {
  status: PropTypes.string,
  data: PropTypes.array,
};

export default function InviteesTable({ data, status }) {
  function createData(name, date, time, transactions, amount) {
    return {
      date,
      name,
      time,
      transactions,
      amount,
    };
  }
  const tabledata = data?.map((item) =>
    createData(
      item.username,
      format(new Date(item.createdAt), 'mm:ss'),
      format(new Date(item.createdAt), 'mm:ss'),
      item.transactions,
      item.amountEarned
    )
  );
  const tableheaders = [
    { name: 'user name' },
    { name: 'sign-up date' },
    { name: 'time' },
    { name: 'transaction performed' },
    { name: 'amount earned' },
  ];

  return (
    <div className="mt-3">
      {status === 'pending' ? (
        <Card>
          <CardHeader title="" sx={{ bgcolor: '#F1F7FA' }} />
          <CardContent
            sx={{
              p: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image src="/images/alarm.svg" height={205} width={205} />
          </CardContent>
        </Card>
      ) : (
        <TableContainer component={Box} className="mx-auto w-100">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableheaders.map((tablehead, index) => (
                  <StyledTableCell
                    key={index}
                    style={{
                      color: '#FF6700',
                      backgroundColor: '#F9843533',
                      fontSize: '.7rem',
                    }}
                    align={tablehead.align || 'center'}
                  >
                    {tablehead.name?.toUpperCase()}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            {tabledata.length !== 0 ? (
              tabledata?.map((row) => (
                <TableBody className="w-100">
                  <StyledTableRow key={row.index}>
                    <StyledTableCell component="th" scope="row">
                      <div className="bold theme-font font-small">
                        {row.name}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <div
                        className="bold theme-font font-small"
                        style={{ width: '100px' }}
                      >
                        {row.date}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <div className="theme-font-2">{row.time}</div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <div className="theme-font-2">{row.transactions}</div>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="theme-font-2">{row.amount}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              ))
            ) : (
              <div
                className="mx-auto fw-bold fs-16 w-100 text-center"
                style={{ color: '#444' }}
              >
                No invite sent
              </div>
            )}

            {/* <TableFooter className="blue-bg text-blue" style={{with: '100%'}}> */}
            {/*   <div className="row"> */}
            {/*     <div className='d-flex justify-content-end'> */}
            {/*       <Box>1-6 0f 6</Box> */}
            {/*       <Box> */}
            {/*         <IconButton> */}
            {/*           <ArrowBackIosNewOutlinedIcon /> */}
            {/*         </IconButton> */}
            {/*         <IconButton> */}
            {/*           <ArrowForwardIosOutlined /> */}
            {/*         </IconButton> */}
            {/*       </Box> */}
            {/*     </div> */}
            {/*   </div> */}
            {/* </TableFooter> */}
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
