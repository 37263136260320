import { createContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import Layout from '../../../components/shared/Layout';
import CardDetails from "../../components/TransactionComponents/CardDetails";
import TransactionDetails from "../../components/TransactionComponents/TransactionDetails";
import { viewTransaction } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";

export const TransactionContext = createContext();

const Transaction = () => {
  const match = useMatch("/transaction/:id");

  const id = match ? match.params.id : "";
  const navigate = useNavigate()

  const [state, setState] = useState({
    referenceID: "",
    transactionCategory: "",
    terminalType: "",
    paymentMethod: "",
    amount: "",
    terminalId: "",
    paymentStatus: "",
  });

  useEffect(() => {
    apiCall({
      method: "get",
      url: `${viewTransaction}?id=${id}`,
      data: id,
      defaultMeta: true,
      success: (res) => {
        const {
          paymentStatus,
          paymentMethod,
          transactionCategory,
          transactionDate,
          terminalType,
          de4,
          de41,
          de37,
          terminals:{merchants:{firstname,surname}}
        } = res;
        setState((state) => ({
          ...state,
          paymentStatus,
          paymentMethod,
          transactionCategory,
          terminalType,
          businessName:`${firstname} ${surname}`,
          amount: parseInt(de4),
          terminalId: de41,
          referenceID: de37,
          transactionDate
        }));
      },
    });
  }, []);
  return (
    <Layout title="Transactions">
      <TransactionContext.Provider
        value={{
          state,
        }}
      >
        <div className="font-default address">
          <h4 className="text-darker fs-14 fw-700 cursor-pointer">
            <span className="mr-15">
              <Back onClick={()=>navigate(-1)}/>
            </span>
            Back
          </h4>

          <Row className="mt-20">
            <TransactionDetails />
            <CardDetails />
          </Row>
        </div>
      </TransactionContext.Provider>
    </Layout>
  );
};

export default Transaction;
