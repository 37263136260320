import Button from '@material-ui/core/Button';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FiFilter, FiSearch } from 'react-icons/fi';
import SimpleDropDown from './DropDown';

const SimpleSearchBox = ({
  filter = false,
  items,
  selected,
  setSelected,
  icon = <FiFilter className="mt-2 text-muted" />,
  filterClss,
  placeholder = 'search here...',
  camel = false,
  handleSearch,
  clearSearch,
  showSearchIcon,
  autoSearch,
  simpleDateFilter,
  dateFilterMenuName,
}) => {
  const [val, setVal] = useState('');
  const handleChange = (e) => {
    handleSearch(e.target.value);
    setVal(e.target.value);
  };
  const handleChangeLocal = (e) => {
    setVal(e.target.value);
  };
  const handleClear = () => {
    clearSearch();
    setVal('');
    // e.target.reset();
  };
  const debouncedChangeHandler = useMemo(
    () => autoSearch && debounce(handleChange, 1200),
    []
  );

  const searchNow = () => handleSearch(val);

  const v1 = (
    <div className="input_Search d-flex flex-wrap align-items-center  w-100 ">
      <div className="">
        <div className="justify-content-center align-items-center">
          {filter ? (
            <div className="d-flex align-items-center">
              {icon}
              <SimpleDropDown
                items={items}
                selected={selected}
                setSelected={setSelected}
                clss={filterClss}
                camel={camel}
              />
            </div>
          ) : (
            <FiSearch color="#FF6700" />
          )}
        </div>
      </div>
      {selected === dateFilterMenuName ? (
        simpleDateFilter
      ) : (
        <form className="w-100 ms-1">
          <div className="d-flex justify-content-between">
            {showSearchIcon && (
              <div className="justify-content-center align-items-center">
                <FiSearch color="#FF6700" />
              </div>
            )}
            <div className="w-100 me-3 ms-2">
              <input
                className="input pe-2"
                onChange={debouncedChangeHandler}
                onChangeCapture={handleChangeLocal}
                placeholder={placeholder}
                value={val}
              />
            </div>
            <div className="justify-content-center align-items-center">
              {filter && val !== '' && (
                <button
                  onClick={handleClear}
                  className="btn-sm btn"
                  type="submit"
                >
                  <FaTimes className="text-muted" />
                </button>
              )}
            </div>
          </div>
        </form>
      )}
    </div>
  );

  const v2 = (
    <div className="d-md-flex align-items-center  w-100 ">
      <div className="">
        <div className="justify-content-center align-items-center">
          {filter ? (
            <div className="d-flex align-items-center">
              {icon}
              <SimpleDropDown
                items={items}
                selected={selected}
                setSelected={setSelected}
                clss={filterClss}
                camel={camel}
              />
            </div>
          ) : (
            <FiSearch color="#FF6700" />
          )}
        </div>
      </div>
      {selected === dateFilterMenuName ? (
        simpleDateFilter
      ) : (
        <form className="w-100 input_Search ms-md-3">
          <div className="d-flex justify-content-between">
            {showSearchIcon && (
              <div className="justify-content-center align-items-center">
                <FiSearch color="#FF6700" />
              </div>
            )}

            <div className="w-100 me-3 ms-2">
              <input
                className="input pe-2"
                onChange={debouncedChangeHandler}
                onChangeCapture={handleChangeLocal}
                placeholder={placeholder}
                value={val}
              />
            </div>
            <div className="justify-content-center align-items-center">
              {filter && val !== '' && (
                <button
                  onClick={handleClear}
                  className="btn-sm btn"
                  type="submit"
                >
                  <FaTimes className="text-muted" />
                </button>
              )}
            </div>
          </div>
        </form>
      )}

      {selected !== dateFilterMenuName && (
        <div className="ms-md-3 my-1 my-md-0 float-end">
          <Button
            onClick={searchNow}
            size="large"
            style={{ backgroundColor: 'var(--thirdColor)', color: '#fff' }}
          >
            Search
          </Button>
        </div>
      )}
    </div>
  );

  return autoSearch ? v1 : v2;
};
export default SimpleSearchBox;
