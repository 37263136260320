import React, { useCallback, useContext } from 'react';
import '../../../../components/DisputeComponents/dispute.scss';
import { SettlementContext } from './SettlementPrefence';
import {
  defaultWallet,
  validateUser,
  walletDetails,
} from '../../../../plugins/urls';
import { Refresh } from '@mui/icons-material';
import axios from 'axios';

const SettlementPreferenceNav = () => {
  const { route, onRouteChange } = useContext(SettlementContext);

  const getWalletDetail = useCallback(() => {
    axios.get(`${walletDetails}`).then((res) => {
      if (res.status == 200 && res.data.respCode == 0) {
        // make default wallet
        axios.post(`${validateUser}`).then((res1) => {
          console.log({ res });
          axios.post(
            `${defaultWallet}?merchantId=${res.data.respBody?.merchantId}&profileId=${res1?.data?.data?.profileSubscription?.profileId}`
          );
        });
      }
    });
  }, []);
  return (
    <div className="Nav-Bar">
      <div className="nav-navigation">
        <ul className="nav-ul">
          <li
            onClick={() => onRouteChange('waya')}
            className={route === 'waya' ? 'nav-li-active' : null}
          >
            {'Settlement Account'}
          </li>

          <li
            onClick={() => onRouteChange('auth')}
            className={route === 'auth' ? 'nav-li-active' : null}
          >
            Payment Preferences
          </li>
          <li onClick={getWalletDetail}>
            Refresh <Refresh />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SettlementPreferenceNav;
