import { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Switch } from '@mui/material';
import './styles.scss';
import { DropDownFilter } from '../../../../components/UI/DropDownFilter';
import {
  addBank,
  bankList,
  verifyMerchantBankAccount,
} from '../../../../plugins/urls';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Slide, toast } from 'react-toastify';
import _ from 'lodash';
import { getUser } from '../../../../utilities/objectOperations';
import { blurHandler } from '../../../../utilities/handlers';

export const AddBank = ({ open, onClose, setOpen }) => {
  const [formData, setFormData] = useState({
    accountNumber: null,
    accountName: '',
  });
  const [optionLists, setoptionLists] = useState([]);
  const user = getUser();

  const [sel, setSel] = useState('Choose a bank');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (type === 'checkbox') {
      setFormData((fd) => ({ ...fd, [name]: checked }));
    } else {
      setFormData((fd) => ({ ...fd, [name]: value }));
    }
  };

  const getAllBank = () => {
    axios.get(bankList).then((res) => {
      setoptionLists(res.data.respBody);
    });
  };
  const { accountNumber } = formData;

  const verifyAcctNumber = (data) => {
    axios.post(verifyMerchantBankAccount, data).then((res) => {
      if (res.status === 200 && res.data.respCode === 0) {
        const bankName = res.data.respBody;
        setFormData((dt) => ({
          ...dt,
          accountName: bankName.substring(bankName.indexOf('|') + 1),
        }));
      }
    });
  };

  useEffect(() => {
    _.isEmpty(optionLists) && getAllBank();
    if (sel && accountNumber && accountNumber.length === 10) {
      const bank = optionLists.find(({ bankName }) => bankName === sel);
      verifyAcctNumber({ accountNumber, bankCode: bank?.bankCode });
    } else {
      setFormData((dt) => ({
        ...dt,
        accountName: '',
      }));
    }
  }, [accountNumber, sel]);

  const handleClick = (e) => {
    e.preventDefault();
    const bank = optionLists.find(({ bankName }) => bankName === sel);
    const { accountName, accountNumber, makePrimary } = formData || {};
    const { userId } = user;
    if (bank && accountName && accountNumber && accountNumber.length === 10) {
      setLoading(true);
      const reqBody = {
        ...bank,
        accountName,
        accountNumber,
        makePrimary: makePrimary ? true : false,
        userId,
      };
      axios
        .post(`${addBank}`, reqBody)
        .then((res) => {
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        })
        .finally(() => {
          setLoading(false);
          setOpen(true);
          setFormData({});
        });
    } else {
      toast.error(
        'All fields are required and Account number must be 11 digit',
        {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    }
  };

  const validations = {
    accountNumber: { required: true, maxLength: 10, minLength: 9 },
    accountName: {
      required: true,
      pattern: /^([^0-9]*)$/,
    },
  };

  return (
    <Dialog open={open} onClose={onClose} className={'addBank__dialog'}>
      <DialogTitle>{'ADD BANK ACCOUNT FOR SETTLEMENT'}</DialogTitle>
      <DialogContent>
        <form>
          <div className="addBank__dialog_dropdown">
            <label>Select Bank</label>
            <DropDownFilter
              sel={sel}
              setSel={setSel}
              optionList={optionLists}
              keyVal={{ key: 'bankName', val: 'bankCode' }}
            />
          </div>

          <div className="addBank__dialog_input">
            <label>Account Number</label>

            <div>
              <input
                type={'number'}
                min={10}
                name={'accountNumber'}
                placeholder={'Enter your account number'}
                onChange={handleChange}
                onBlur={(e) => {
                  return blurHandler({
                    e,
                    validations: validations.accountNumber,
                    setErrors,
                    errors,
                  });
                }}
                required
              />
              <span className="error">{errors?.accountNumber}</span>
            </div>
          </div>

          <div className="addBank__dialog_input">
            <label>Account Name</label>
            <div>
              <input
                disabled={true}
                type={'text'}
                name={'accountName'}
                placeholder={'Enter your account name'}
                onChange={handleChange}
                value={formData?.accountName}
                onBlur={(e) => {
                  return blurHandler({
                    e,
                    validations: validations.accountName,
                    setErrors,
                    errors,
                  });
                }}
                required
              />
              <span className="error">{errors?.accountName}</span>
            </div>
          </div>
          <div className="addBank__dialog_input">
            <span>Set Account as default</span>
            <Switch name={'makePrimary'} onChange={handleChange} />
          </div>
          <div className="btn-group">
            <button
              className="button"
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              {'Cancel'}
            </button>
            <button
              className="button"
              disabled={!formData.accountName}
              onClick={handleClick}
            >
              {'Add bank Account'}
              {loading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
