import * as React from 'react';
import { useTheme, Dialog, DialogTitle, Typography, Box } from '@mui/material';

export default function UpdateProfileModal({
  state,
  title,
  actions,
  leftAction,
  rightAction,
  loading,
  ...props
}) {
  const theme = useTheme();
  const styles = {
    '& .MuiDialog-paper.MuiPaper-rounded': {
      minWidth: 370,
      p: 2,
      borderRadius: '8px',
      borderTop: `8px solid ${theme.palette.primary.main}`,
    },
    '.btnClose': {
      position: 'absolute',
      right: '2rem',
      top: 'calc(50% - 1rem)',
    },
    '.title': {
      color: theme.palette.grey[800],
      fontWeight: 'normal',
      textAlign: 'center',
      mb: 2,
    },
    '.subtitle': {
      color: theme.palette.grey[400],
      fontWeight: 'normal',
      textAlign: 'center',
      width: 1,
      display: 'block',
    },
    '.MuiFormControl-root': {
      width: '100%',
    },
    '.MuiDialogActions-root button': {
      width: '100%',
    },
    ...props.sx,
  };
  return (
    <Dialog open={state} {...props} sx={styles} loading={false}>
      <div className="d-flex align-items-center justify-content-between">
        <DialogTitle sx={{ position: 'relative' }}>
          <Box>
            {title && (
              <Typography variant="h4" className="title" color="blue">
                {title}
              </Typography>
            )}
          </Box>
        </DialogTitle>
        {rightAction}
      </div>
      {props.children}
    </Dialog>
  );
}
