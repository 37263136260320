import React, { useContext, useEffect, useCallback } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import './styles.scss';
import Cards from '../Cards/Cards';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as Recall } from '../../assets/icons/recall.svg';

import { DashboardContext } from '../../pages/Dashboard/Dashboard';
import { CustomIcon } from '../../utilities/CustomIcon';
import axios from 'axios';
import { walletDetails } from '../../plugins/urls';
import { useState } from 'react';
import Modal from '../../components/Modal';
import { NewTopUp } from './NewTopUp';
import { useNavigate } from 'react-router';

const AccountRevenue = () => {
  const [wallet, setWallet] = useState({});
  const [showTopUp, setShowTopUp] = useState({ show: false, frame: 'topUp' });
  const getWalletDetail = useCallback(() => {
    axios.get(`${walletDetails}`).then((res) => {
      if (res.status == 200 && res.data.respCode == 0) {
        setWallet(res.data.respBody);
      }
    });
  }, []);

  const { frame } = showTopUp;
  useEffect(() => {
    getWalletDetail();
  }, []);

  const {
    state: { gross_revenue, net_revenue, commission },
    wallets,
  } = useContext(DashboardContext);
  const navigate = useNavigate();
  return (
    <Row className="mt-40 d-flex justify-content-between">
      <Col xs={6} lg={4} xl={4} className=" mt-2 mt-xl-0 ">
        <Cards
          cardTitle="Gross Revenue"
          value={gross_revenue}
          color="text-orange"
          textColor="text-darker"
          size="fs-14"
        />
      </Col>

      <Col xs={6} lg={4} xl={4} className="mt-2 mt-xl-0">
        <Cards
          cardTitle="Net Revenue"
          value={net_revenue}
          color="text-orange"
          textColor="text-darker"
          size="fs-14"
        />
      </Col>

      <Col xs={6} lg={4} xl={4} className="mt-2 mt-xl-0">
        <div className="d-flex flex-column justify-content-evenly account-div-white py-1 pl-3 h-100">
          <div className="d-flex">
            <h4 className="text-red m-0 text-capitalize fs-16 fw-bold">
              {'Current Balance'}
            </h4>
            <Button
              className="btn-wallet"
              size={'sm'}
              onClick={() =>
                navigate('/transactions', { state: { active: 'agency' } })
              }
            >
              <CustomIcon IconComp={Recall} style={{ marginRight: '4px' }} />
              {'View History'}
            </Button>

            <Button
              className="btn-wallet"
              size={'sm'}
              onClick={() =>
                setShowTopUp((s) => ({ ...s, show: true, frame: 'topUp' }))
              }
            >
              <CustomIcon IconComp={Lock} style={{ marginRight: '4px' }} />
              {'Top Up'}
            </Button>
          </div>
          <div className="">
            <h4 className="fs-18 text-darker m-0 fw-bolder">
              {`NGN ${wallet?.activeBalance}`}
            </h4>
          </div>
          <div className={'d-flex'}>
            <div>
              <h4
                className="text-red m-0 text-capitalize fw-bolder"
                style={{ fontSize: '.7rem' }}
              >
                {'Total Withdraw'}
              </h4>
              <h4 className="fs-12 text-default">
                {`NGN ${wallet?.totalWithdrawal}`}
              </h4>
            </div>
          </div>
        </div>
      </Col>
      <Modal
        show={showTopUp.show}
        onclick={() =>
          setShowTopUp((s) => ({ ...s, show: false, frame: 'topUp' }))
        }
        title={`Topup your wallet`}
        clsx={{ width: frame === 'wayaQuick' ? '75%' : '' }}
      >
        <NewTopUp setShowTopUp={setShowTopUp} />
      </Modal>
    </Row>
  );
};

export default AccountRevenue;
