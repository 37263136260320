import Box from "@material-ui/core/Box";
import { createContext, useEffect, useState } from "react";
import AccountRevenue from "../../components/DashboardComponent/AccountRevenue";
import BarChart from "../../components/DashboardComponent/BarChart";
import DashboardCard from "../../components/DashboardComponent/DashboardCard";
import PieCharts from "../../components/DashboardComponent/PieCharts";
import TransactionCount from "../../components/DashboardComponent/TransactionCount";
// import Layout from "../../components/Layout/Layout";
import Layout from '../../../components/shared/Layout';
import Loader from "../../components/Loader";
import { revenueStats, transactionStats } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import QuickActions from "./QuickActions";

export const DashboardContext = createContext();

const Dashboard = () => {
  const [state, setState] = useState({
    wayaAcc: "",
    wayaAccname: "",
    balance: "",
    failed: "",
    refunded: "",
    pending: "",
    abandoned: "",
    successful: "",
    gross_revenue: "",
    net_revenue: "",
    commission: "",
  });
  const [loading, setLoading] = useState([]);
  const [charges, setCharges] = useState([]);

  useEffect(() => {
    getTransactionsStats();
    getRevenueStats();
    //getCommission();
  }, []);

  const getTransactionsStats = () => {
    apiCall({
      method: "post",
      setLoading,
      url: `${transactionStats}`,
      noError: true,
      defaultMeta: true,
      success: (res) => {
        const { failed, refunded, abandoned, successful } = res;
        setState((state) => ({
          ...state,
          failed,
          refunded,
          abandoned,
          successful,
        }));
      },
    });
  };

  //get auth user default wallet

  const getRevenueStats = () => {
    apiCall({
      method: "get",
      setLoading,
      url: `${revenueStats}`,
      noError: true,
      defaultMeta: true,
      success: (res) => {
        const { gross_revenue, net_revenue } = res;
        setState((state) => ({
          ...state,
          gross_revenue,
          net_revenue,
        }));
      },
    });
  };
  /*  const getCommission = () => {
    apiCall({
      method: "get",
      url: `${commissionStats}/${user.id}`,
      noError: true,
      defaultMeta: true,
      success: (res) => {
        setState((state) => ({
          ...state,
          commission: res.data.clr_bal_amt,
        }));
      },
    });
  }; */

  return (
    <Layout title="Overview">
      {loading ? (
        <div className="row h-100vh">
          <div className="d-flex justify-content-center align-items-center">
            <Box>
              <Loader />
            </Box>
          </div>
        </div>
      ) : (
        <DashboardContext.Provider
          value={{
            state,
            charges,
            loading,
          }}
        >
          <TransactionCount />
          <AccountRevenue />
          <BarChart />
          <DashboardCard />
          <QuickActions />
          <PieCharts />
        </DashboardContext.Provider>
      )}
    </Layout>
  );
};
export default Dashboard;
